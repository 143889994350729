<div class="content-wrapper">
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-auto">
          <h1 class="m-0 pl-4 subtitle">Módulo Oficinas</h1>
        </div>
        <div class="col-sm-6 mt-1 border-left border-right">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a href="#">Admin Oficinas</a></li>
            <li class="breadcrumb-item active">Oficinas</li>
          </ol>
        </div>
      </div>
    </div>
  </div>

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <!-- Main row -->
      <div class="row">
        <!-- Left col -->
        <section class="col-lg-12 pl-3 pr-3 connectedSortable">
          
            <div class="card  box">
              <div class="row p-3">
                <div class="col-12">
                  <h5>Asignar Oficinas</h5>
                  <button type="button" (click)="Seleccionar()" class="btn btn-block btn-success"
                    *ngIf="sesionrol == 1"><i class="fas fa-cogs"></i> Agregar
                    Nueva Oficina</button>
                </div>
                <div class="p-1"></div>
                <div class="col-12">
                  <!-- table table-responsive table-hover mt-2 -->
                  <div *ngIf="oficinasV2">

                    <p-table #dt1 [value]="oficinasV2" (onFilter)="onFilter($event, dt)" dataKey="id" [rows]="10"
                      [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
                      styleClass="p-datatable-customers p-datatable-gridlines" [paginator]="true"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                      [globalFilterFields]="['idOficina','sOficina', 'sCorreo', 'bEstado']">
                      <ng-template pTemplate="caption">
                        <div class="p-d-flex flex-column">
                          Listar Oficinas
                          <div class="row">
                            <button class="btn btn-success ml-2" (click)="exportExcel(dt1['filteredValue'])"
                              *ngIf="oficinasV2.length > 0"><i class="far fa-file-excel"></i> Exportar a Excel</button>
                            <button class="btn btn-warning ml-2" (click)="exportPdf(dt1['filteredValue'])"
                              *ngIf="oficinasV2.length > 0"><i class="far fa-file-pdf"></i> Exportar a PDF</button>
                          </div><br>
                          <div class="row">
                            <!-- <span class="ml-2">Click sobre el texto a editar</span> -->
                            <span class="p-input-icon-left p-ml-auto mr-2">
                              <i class="pi pi-search"></i>
                              <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                placeholder="Buscar en Tabla" />
                            </span>
                          </div>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="header">
                        <tr>
                          <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                              ID OFICINA
                            </div>
                          </th>
                          <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                              NOMBRE
                            </div>
                          </th>
                          <th>
                            <div class="p-d-flex p-jc-between p-ai-center">
                              EMAIL
                            </div>
                          </th>
                          <!-- <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            DESDE CASILLERO
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            HASTA CASILLERO
                          </div>
                        </th> -->
                          <!-- <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            ESTADO
                          </div>
                        </th> -->
                          <th *ngIf="sesionrol != 2 ">
                            <div class="p-d-flex p-jc-between p-ai-center">
                              PRESIONE PARA GUARDAR CAMBIOS
                            </div>
                          </th>
                          <th *ngIf="sesionrol != 2">
                            <div class="p-d-flex p-jc-between p-ai-center">
                              PRESIONE PARA ELIMINAR
                            </div>
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-customer let-i="rowIndex">
                        <tr>
                          <td pEditableColumn>
                            <p-cellEditor>
                              <ng-template pTemplate="input">
                                <input pInputText type="text" [(ngModel)]="customer.sCodOfi" />
                              </ng-template>
                              <ng-template pTemplate="output">
                                <div style="cursor: pointer;">
                          <td>
                            {{customer.sCodOfi}}
                          </td>
                  </div>
                  </ng-template>
                  </p-cellEditor>
                  </td>
                  <td pEditableColumn>
                    <p-cellEditor>
                      <ng-template pTemplate="input">
                        <input pInputText type="text" [(ngModel)]="customer.sOficina" />
                      </ng-template>
                      <ng-template pTemplate="output">
                        <div style="cursor: pointer;">
                  <td>
                    {{customer.sOficina}}
                  </td>
                </div>
                </ng-template>
                </p-cellEditor>
                </td>
                <td pEditableColumn>
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="email" [(ngModel)]="customer.sCorreo" />
                    </ng-template>
                    <ng-template pTemplate="output">
                      <div style="cursor: pointer;">
                        {{customer.sCorreo}}
                      </div>
                    </ng-template>
                  </p-cellEditor>
                </td>
                <!-- <td>
                          <select class="form-control w-100" [(ngModel)]="customer.desde">
                              <option selected style="color: red;" disabled>{{customer.desde}}</option>
                              <option *ngFor="let Ofi of numeros" [ngValue]="Ofi"> {{Ofi.num}}</option>
                          </select>
                        </td>
                        <td>
                          <select class="form-control w-100"
                          [(ngModel)]="customer.hasta">
                              <option selected style="color: red;" disabled>{{customer.hasta}}</option>
                              <option *ngFor="let Ofi of numeros" [ngValue]="Ofi"> {{Ofi.num}}</option>
                          </select>
                        </td> -->
                <!-- <td>
                          <select class="form-control w-100">
                              <option value="true">Habilitado</option>
                              <option value="false">Deshabilitado</option>
                          </select>
                        </td> -->
                <td (click)="editarOficina(customer)" *ngIf="sesionrol ==1">
                  <i class="fas fa-edit" style="cursor: pointer;"></i>
                </td>
                <td (click)="EliminarCliente(customer)" *ngIf="sesionrol == 1">
                  <i class="fas fa-trash" style="cursor: pointer;"></i>
                </td>
                </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="10">No hay oficinas para mostrar.</td>
                  </tr>
                </ng-template>
                </p-table>

              </div>
            </div>
      </div>
    </div>
    
  </section>
  <!-- /.Left col -->
  <!-- right col (We are only adding the ID to make the widgets sortable)-->
  <!-- right col -->
</div>
<!-- /.row (main row) -->
</div>
<!-- /.container-fluid -->
</section>
<!-- /.content -->
</div>
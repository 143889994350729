
<div class="d-flex cntr-stado-locker">
    <div class="font-weight-bold">
        Vista cajas fuertes
    </div>
</div>
<div class="d-flex flex-column marginlockr">

    <!-- <div class="d-flex "> -->
        <div class="row separacion">
            <div  class="columnalocker border-t border-b">
                <div class="row ml-1 mt-3 mb-2">
                    <!-- card -->
                    <ng-container *ngFor="let suc of sucursales"  >
                        
                        <div class="card w-10 border-success rounded m-2" *ngIf="suc.id == this.idoficina" 
                        >
                           
                            <div class="card-body text-white m-1 rounded border-dark" 
                            [ngClass]="suc.abierto ? 'classTrue' : suc.cerrado ? 'classFalse' : 'classOffline'" >
                                <h3 class="catitletle">Suc N° :&nbsp;{{suc.codofi}}</h3> <br><br>
                                <h5 class="catitletle">{{suc.title}}</h5> <br><br>
                              </div>
                              <div class="card-body" style="display: flex;flex-direction: row;">
                                <button href="#" class="btn btn-success ml-2 mr-2"(click)="envioData(suc.codofi)">Abrir</button>
                                <button href="#" class="btn btn-danger ml-2 mr-2" (click)="reset(suc.codofi)" >Reset</button>
                                <!-- <button href="#" class="btn btn-secondary ml-2 mr-3" ></button> -->
                                
                              </div>
                        </div>
                        <div class="card w-10 border-success rounded m-2" *ngIf="'0' == this.idoficina"
                        >
                            
                            <div class="card-body text-white m-1 rounded border-dark" 
                            [ngClass]="suc.abierto ? 'classTrue' : suc.cerrado ? 'classFalse' : 'classOffline'" >
                                <h3 class="catitletle">Suc N° :&nbsp;{{suc.codofi}}</h3> <br><br>
                                <h5 >{{suc.title}}</h5> <br><br>
                                
                              </div>
                              <div class="card-body" style="display: flex;flex-direction: row;">
                                <button href="#" class="btn btn-success ml-3 mr-2" (click)="envioData(suc.codofi)">Abrir</button>
                                <button href="#" class="btn btn-danger ml-2 mr-2" (click)="reset(suc.codofi)" >Reset</button>
                                <!-- <button href="#" class="btn btn-secondary ml-2 mr-3" ></button> -->
                                
                              </div>
                        </div>
                    </ng-container>
                    
                    <!-- <div class="casilleroLocker" >
                        <div class="idcasi">Prueba 2</div>
                    </div>
               <div class="idcasi">prueba 3</div>
                    </div> -->
    
                </div>
            </div>
        </div>

    <!-- </div> -->
    

    <div class="d-flex cntr-stado-locker">
        <div class="d-flex w-25 align-items-center justify-content-center">
            <div class="Libre">
            </div>
            <div class="font-weight-bold">
                Abierto
            </div>
        </div>

        <div class="d-flex w-25 align-items-center justify-content-center">
            <div class="Ocupado">
            </div>
            <div class="font-weight-bold">
                Cerrado
            </div>
        </div>

        <div class="d-flex w-25 align-items-center justify-content-center">
            <div class="Expirado">
            </div>
            <div class="font-weight-bold">
                Offline
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { PruebasService } from 'src/app/core/services/http/pruebas.service';
import * as mqtt from "mqtt/dist/mqtt.min"
import { ConfigService } from '../../../core/services/config/config.service';



@Component({
  selector: 'app-safe',
  templateUrl: './safe.component.html',
  styleUrls: ['./safe.component.scss']
})
export class SafeComponent implements OnInit, OnDestroy {

  open: boolean
  close: boolean
  offline: boolean
  finalizar
  Locker = {
    nombre: "gustavo"
  }
  ConsulOfi;
  idoficina;
  client_id

  url;

  // cliente mtqq
  Clientmtqq: any;
  public sucursales: any = []
  constructor(private SweetAlertService: SweetAlertService, private PruebasService: PruebasService, private ConfigService:ConfigService) {

  }
  ngOnDestroy(): void {
    this.ConsulOfi = false;
    this.Clientmtqq.end();
  }

  ngOnInit(): void {
    this.ConsulOfi = true; 
    this.consultarCambioOficina();
    this.CrearPuertas();
   
    
  }

  async CrearPuertas() {
    this.SweetAlertService.swalLoading();
    const config = await this.ConfigService.getConfig();
    this.url = config['wss'];
    let resp = await this.PruebasService.obtenerLockers();
    // console.log(resp);
    if (resp["status"] = true) {
      this.sucursales = []
      for (let index = 0; index < resp["data"].length; index++) {
        const element = resp["data"][index];
        const sucursal = {
          idLocker: element["idLocker"],
          id: element["idOficina"],
          title: element["sNomLocker"],
          codofi:element["codofi"],
          abierto: false,
          cerrado: false,
          offline: true
        }
        this.sucursales.push(sucursal);

      }
      this.SweetAlertService.swalClose();
    }
    this.ws();
  }

  async consultarCambioOficina() {
    if (this.ConsulOfi == true) {
      if (this.idoficina != localStorage.getItem("ofiselect")) {
        this.idoficina = localStorage.getItem("ofiselect")
      }
      setTimeout(() => {
        this.consultarCambioOficina();
      }, 1100);

    }
  }

  estadoCaja(sucursal: any, estado: boolean) {
    this.sucursales.forEach(element => {
      if (element.codofi == sucursal.codofi) {
        element.abierto = !estado
      }
    });
  }

  // cambia color de caja a verde
  estadoAbierto(id: any): void {
    // this.SweetAlertService.swalLoading();
    
    this.sucursales.forEach((sucursal, i) => {
      if (id == sucursal.codofi) {
        sucursal.abierto = true
        sucursal.cerrado = false
        sucursal.offline = false
        return;
      }
    });
  }
  // cambia color de caja a rojo
  estadoCerrado(id: any): void {
    this.sucursales.forEach((sucursal, i) => {
      if (id == sucursal.codofi) {
        sucursal.abierto = false
        sucursal.cerrado = true
        sucursal.offline = false
        return;
      }
    });

  }
  // cambia color de caja a gris
  async estadoOffline(id: any): Promise<any> {
    //await this.SweetAlertService.swalInfo('Caja fuerte quedara en estado offline')
    await this.sucursales.forEach((sucursal, i) => {
      if (id == sucursal.codofi) {
        sucursal.abierto = false
        sucursal.cerrado = false
        sucursal.offline = true
        return;
      }
    });
  }

  changeStatus() {
    this.sucursales.abierto = !this.sucursales.abierto;
    // console.log('119', this.sucursales.abierto);
  }

  // coneccion al ws oxxo
  async envioData(door: any) {    
    const to_send = { command: true, }
    console.log(`/oxxo/${door}/door_action`, JSON.stringify(to_send));
    this.Clientmtqq.publish(`/oxxo/${door}/door_action`, JSON.stringify(to_send))
    /**lo de abajo provoca que QoS*/
   // console.log('publish');
    this.sucursales.forEach((sucursal, i) => {
      if (door == sucursal.codofi) {
        const usuario = JSON.parse(localStorage.getItem("usulogeado"));
        this.PruebasService.CargaLocker({
          rutUsuario: usuario.sDniUsu,
          rutCliente: "1-9",
          idCasillero: 1,
          idLocker: sucursal.idLocker,
          idOficina: sucursal.id,
        });
        return;
      }
    });
    
  }

  reset(door:any){

    const to_send = { action: "reset", }
    this.Clientmtqq.publish(`/oxxo/${door}/door_action`, JSON.stringify(to_send))
     console.log(`/oxxo/${door}/door_action`, JSON.stringify(to_send));
  }
  /**Se crea la conexión, se suscribe al topico, se termina la conexion luego de 30 segundos */
  ws() {
     this.client_id = "front_client_" + Math.random();

    var options = {
      connectTimeout: 4 * 1000,
      keepalive: 60,
      clientId: this.client_id,
      clean: true,
      // reconnectPeriod: 0
    }

    const compatible_ids = ["00001", "00002", "00003", "00004"];

    const WS_URL = this.url;
     this.Clientmtqq = mqtt.connect(WS_URL, options);

    this.Clientmtqq.on('connect', () => {
      console.log('client connected:' + this.client_id)
      this.Clientmtqq.subscribe('/oxxo/+/door_status', function (err) {
        if (!err) {
          console.log("SUBSCRIBE - SUCCESS");
        } else {
          console.log("SUBSCRIBE - ERROR");
        }
      })
    })

    this.Clientmtqq.on('reconnect', (error) => {
      console.log('reconnecting' + error)
    })
    this.Clientmtqq.on('error', (error) => {
      console.log('error!' + error)
    })

    this.Clientmtqq.on('message', (topic, message) => {
      // topic /oxxo/00006/door_status:
      //message {"door_status": "close"}
      console.log(topic + ": " + message.toString())
      let command_mqtt = topic.split("/")

      //if (compatible_ids.includes(command_mqtt[2])) {
        if (command_mqtt[3] == "door_status") {
          // let command_mqtt_door = "door_status_doom_" + command_mqtt[2];
          //console.log(command_mqtt_door); //el 2 indica la puerta
          //console.log('146', JSON.parse(message).door_status);
          if ('close' === JSON.parse(message).door_status) {
            //this.sucursales.status = 'close'
            this.estadoCerrado(parseInt(command_mqtt[2] + ""));
            // console.log(this.sucursales.status);
          }
          else if ('open' === JSON.parse(message).door_status) {
            // document.getElementById(command_mqtt_door).innerHTML = 'Door Status : Open <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-door-open-fill" viewBox="0 0 16 16"><path d="M1.5 15a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1H13V2.5A1.5 1.5 0 0 0 11.5 1H11V.5a.5.5 0 0 0-.57-.495l-7 1A.5.5 0 0 0 3 1.5V15H1.5zM11 2h.5a.5.5 0 0 1 .5.5V15h-1V2zm-2.5 8c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z"/></svg>';
            this.estadoAbierto(parseInt(command_mqtt[2] + ""));
          }
          else if ('offline' === JSON.parse(message).door_status) {
            //  console.log('156',JSON.parse(message));
            //  console.log('156',parseInt(command_mqtt[2]+"")); el numero de puerta
            this.estadoOffline(parseInt(command_mqtt[2] + ""));
          }
        }

     // }
    })
    setTimeout(() => {
      
    }, 30000);
  }

}

<div class="content-wrapper">
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-auto">
          <h1 class="m-0 pl-4 subtitle">Módulo Clientes</h1>
        </div>
        <div class="col-sm-6 mt-1 border-left border-right">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a href="#">Admin Clientes</a></li>
            <li class="breadcrumb-item active">Clientes</li>
          </ol>
        </div>
      </div>
    </div>
  </div>

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">
      <!-- Main row -->
      <div class="row">
        <!-- Left col -->
        <section class="col-lg-12 pl-3 pr-3 connectedSortable">
          <div class="card  box">
            <div class="row p-3">
              <div class="col-12 col-md-8">
                <h5>Asignar Cliente </h5>
              </div>
              <div class="col-6 col-md-4 text-right">
                <button type="button" (click)="Seleccionar()" 
                        class="btn btn-block btn-success" 
                        *ngIf="sesionrol == 1">
                  <i class="fas fa-cogs"></i> 
                  Agregar Nuevo Cliente
                </button><br><br>
              </div>
              <div class="col-12">
                <div class="col-12">
                  <div class="table-responsive">
                    <table class="table table-bordered" width="100%"
                      cellspacing="0">
                      <thead>
                        <tr>
                          <th class="text-center" style="background: #f8f9fa;">Cliente</th>
                          <th class="text-center" style="background: #f8f9fa;">Rut</th>
                          <th class="text-center" style="background: #f8f9fa;">Correo</th>
                          <th class="text-center" style="background: #f8f9fa;">Telefono</th>
                          <th *ngIf="sesionrol == 1" class="text-center" style="background: #f8f9fa;">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr [usuario]="clt" *ngFor="let clt of resClientes">
                          <td>{{clt.nombre}}</td>
                          <td>{{clt.rutCliente}}</td>
                          <td>{{clt.correo}}</td>
                          <td>{{clt.telefono}}</td>
                          <td class="text-center" *ngIf="sesionrol == 1">
                            <a class="btn btn-success btn-circle btn-sm mr-2"
                              (click)="seleccionarCliente(clt)"
                              title="Editar">
                              <i class="fas fa-pen"></i>
                            </a>
                            <a class="btn btn-danger btn-circle btn-sm mr-2" 
                            (click)="EliminarCliente(clt.rutCliente)"
                              title="Eliminar">
                              <i class="fas fa-times"></i>
                            </a>
                            
                          </td>
                        </tr>
                        
                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Cliente</th>
                          <th class="text-center" style="background: #f8f9fa;">Rut</th>
                          <th class="text-center" style="background: #f8f9fa;">Correo</th>
                          <th class="text-center" style="background: #f8f9fa;">Telefono</th>
                          <th *ngIf="sesionrol == 1" class="text-center" style="background: #f8f9fa;">Acciones</th>
                        </tr>
                      </tfoot>
                      
                    </table>
                  </div>
                </div>
                <!-- table table-responsive table-hover mt-2 -->
                <!-- <div *ngIf="clientesV2" style="height: 700px;">

                  <p-table #dt1 [value]="clientesV2" (onFilter)="onFilter($event, dt)" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
                    styleClass="p-datatable-customers p-datatable-gridlines" [paginator]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [globalFilterFields]="['sDniUsu','sNombreUsu', 'sPassUsu', 'bHab','idRol','NombreOfi','NombreRol']">
                    <ng-template pTemplate="caption">
                      <div class="p-d-flex">
                        Usuarios Actuales
                        <div class="row">
                          <button class="btn btn-success"(click)="exportExcel(dt1['filteredValue'])" 
                          *ngIf="clientesV2.length > 0" style="position: relative; left: 320px;">
                          <i class="far fa-file-excel"></i> Exportar a Excel</button>
                          <button class="btn btn-warning"(click)="exportPdf(dt1['filteredValue'])" 
                          *ngIf="clientesV2.length > 0"><i class="far fa-file-pdf"></i> Exportar a PDF</button>
                        </div>
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar en Tabla" />
                        </span>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                      <tr>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            ID ROL
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            RUT
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            NOMBRE
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            CLAVE
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            OFICINA
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Disp a usar
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            ESTADO
                          </div>
                        </th>
                        <th *ngIf="sesionrol != 1">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            PRESIONE PARA GUARDAR
                          </div>
                        </th>
                        <th *ngIf="sesionrol != 1">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            PRESIONE PARA ELIMINAR
                          </div>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-customer>
                      <tr>
                        <td>
                          <select class="form-control w-100"
                          [(ngModel)]="customer.idRol">
                              <option *ngFor="let res of resCliente"
                                  [ngValue]="Rol.idRol">
                                  {{res.nombre }}</option>
                          </select>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="text" maxlength="12" [(ngModel)]="customer.sDniUsu" (change)="validaRut()"/>
                            </ng-template>
                            <ng-template pTemplate="output">
                              <div>
                                <td>
                                  {{customer.sDniUsu | rut}}
                                </td>
                              </div>
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="text" (keypress)="validateFormat($event)" [(ngModel)]="customer.sNombreUsu" />
                            </ng-template>
                            <ng-template pTemplate="output">
                              <div>
                                <td>
                                  {{customer.sNombreUsu}}
                                </td>
                              </div>
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="password" [(ngModel)]="customer.sPassUsu" />
                            </ng-template>
                            <ng-template pTemplate="output">
                              <div>
                                ****
                              </div>
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td>
                          <select class="form-control w-100"
                          [(ngModel)]="customer.idOficina">
                              <option value="1" >--Sin oficina--</option>
                              <option *ngFor="let Ofi of ListaOFicinas" [ngValue]="Ofi.idOficina"> {{Ofi.sOficina }}</option>
                          </select>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="text" [(ngModel)]="customer.cantElemt" />
                            </ng-template>
                            <ng-template pTemplate="output">
                              <div>
                                <td>
                                  {{customer.cantElemt}}
                                </td>
                              </div>
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td>
                          <select class="form-control w-100" [(ngModel)]="customer.bHab">
                              <option value="true">Habilitado</option>
                              <option value="false">Deshabilitado</option>
                          </select>
                        </td>
                        <td (click)="traerdatos(customer)" *ngIf="sesionrol != 1">
                          <i class="fas fa-edit" style="cursor: pointer;"></i>
                        </td>
                        <td (click)="EliminarCliente(customer)" *ngIf="sesionrol != 1">
                          <i class="fas fa-trash" style="cursor: pointer;"></i>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="10">No hay clientes para mostrar.</td>
                      </tr>
                    </ng-template>
                  </p-table>

                </div> -->
              </div>
            </div>
          </div>
        </section>
        <!-- /.Left col -->
        <!-- right col (We are only adding the ID to make the widgets sortable)-->
        <!-- right col -->
      </div>
      <!-- /.row (main row) -->
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- /.content -->
</div>
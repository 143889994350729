import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { TarjetaLockerService } from '../../core/services/http/tarjeta-locker.service';
import { RutService } from 'src/app/core/services/http/rut.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-menu-opciones',
  templateUrl: './menu-opciones.component.html',
  styleUrls: ['./menu-opciones.component.scss']
})
export class MenuOpcionesComponent implements OnInit, OnDestroy {
  total: [] = [];
  Disponibles: [] = [];
  Usados: [] = [];
  Expirados: [] = [];
  idoficina;
  idLocker;
  ConsulOfi = true;
  rolsesion;
  rutValid: boolean = false;

  registerForm: FormGroup;

  constructor(
    private router: Router,
    private PruebasService: PruebasService,
    private SweetAlertService: SweetAlertService,
    private rutService: RutService,
    private formBuilder: FormBuilder,
    private TarjetaLockerService: TarjetaLockerService) { }

  async ngOnInit(): Promise<void> {
    this.registerForm = this.formBuilder.group({
      dniUsu: ['', [Validators.required, Validators.minLength(8)]]
    })
    this.rolsesion = localStorage.getItem("rolsesion");
    const usuario = JSON.parse(localStorage.getItem("usulogeado"));
    this.idoficina = usuario["idOficina"]
    //var resplocker = await this.TarjetaLockerService.DibujoCasillero(this.idoficina);
    // this.idLocker = resplocker["data"][0]["idLocker"]
    // await this.traerDatosElementos();
    // this.consultarCambioOficina();
  }

  async traerDatosElementos() {
    this.SweetAlertService.swalLoading();
    var data = {
      "idOficina": this.idoficina,
      "idLocker": this.idLocker
    }
    let response = await this.PruebasService.GetDatoadeldia(data);
    if (response["status"]) {
      this.total = response["data"]["total"];
      this.Disponibles = response["data"]["libres"];
      this.Usados = response["data"]["usados"];
      this.Expirados = response["data"]["vencidos"]
    }
    else {
      this.SweetAlertService.swalError("no se puede crear elementos en este momento, vuelva a intentar");
    }
    this.SweetAlertService.swalClose();
  }

  // async consultarCambioOficina() {
  //   if (this.ConsulOfi == true) {
  //     if (this.idoficina != localStorage.getItem("ofiselect")) {
  //       this.idoficina = localStorage.getItem("ofiselect")
  //       const resplocker = await this.TarjetaLockerService.DibujoCasillero(this.idoficina);
  //       console.log(resplocker);
  //       this.idLocker = resplocker["data"][0]["idLocker"];
  //       this.traerDatosElementos();
  //     }
  //     setTimeout(() => {
  //       this.consultarCambioOficina();
  //     }, 1100);

  //   }
  // }

  // cliente metodos 

  setRut(): void {
    this.rutValid = this.rutService.validarRut(
      this.registerForm.value.dniUsu.replace(/\./g, '').replace('-', '')
    );
    this.registerForm.patchValue({
      dniUsu: this.rutService.formateaRut(this.registerForm.value.dniUsu),
    });

    if (this.rutValid) {
      // Validando que el rut ingresado contenga sólo números y la letra K
      const rutRegex = /^\d{1,3}(?:\.\d{1,3}){2}-[\dkK]$/;
      this.rutValid = rutRegex.test(this.registerForm.value.dniUsu);
    }
  }

  validaRut(): void {
    this.rutValid = this.rutService.validarRut(this.registerForm.value.rut.replace(/\./g, '').replace('-', ''));
    this.registerForm.patchValue({ rut: this.rutService.formateaRut(this.registerForm.value.registerForm) })
  }

  formDirtyRequire(dato: any) {
    return this.registerForm.get(dato).errors?.dirty && this.registerForm.get(dato).errors?.required
  }

  formTouchLenght(dato: any) {
    return this.registerForm.get(dato).touched && this.registerForm.get(dato).errors?.minLength
  }
  formTouched(dato: any) {
    return this.registerForm.get(dato)?.touched
  }

  async onSubmit(){
    var resp = await this.PruebasService.BusquedaPedidoCliente(this.registerForm.value.dniUsu);
    console.log(resp);
    
    if (resp["status"] == true) {
      this.SweetAlertService.BusquedaCLiente("Cliente Encontrado",resp["message"]);
    }
    else{
      this.SweetAlertService.BusquedaCLiente("Cliente No Encontrado",resp["message"]);
    }
    
    console.log('Enviando');
    
  }
//------------------------
  ngOnDestroy(): void {
    this.ConsulOfi = false;
  }


}

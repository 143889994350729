
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EmitTecladoService } from 'src/app/core/services/emit/emit-teclado.service';
import { ModalTecladoService } from 'src/app/core/services/modals/modal-teclado.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss']
})
export class KeyboardComponent implements OnInit, OnDestroy {
  subscripcion: Subscription;
  desde = 0;
  hasta = 0;
  texto = new FormControl('');
  model: string;
  max: 9999;
  tipo = 'text';

  teclas = [
  ];

  numerico = [
    [{ancho: '1', accion: 'add', text: '1'}, {ancho: '1', accion: 'add', text: '2'}, {ancho: '1', accion: 'add', text: '3'}],
    [{ancho: '1', accion: 'add', text: '4'}, {ancho: '1', accion: 'add', text: '5'}, {ancho: '1', accion: 'add', text: '6'}],
    [{ancho: '1', accion: 'add', text: '7'}, {ancho: '1', accion: 'add', text: '8'}, {ancho: '1', accion: 'add', text: '9'}],
    [{ancho: '0', accion: '', text: ''}, {ancho: '1', accion: 'add', text: '0'}, {ancho: '1', accion: 'delete', text: 'delete'}],
    [{ancho: '3', accion: 'enter', text: 'Aceptar'}],
    [{ancho: '3', accion: 'suprimir', text: 'Eliminar'}]
  ];
  decimal = [
    [{ancho: '1', accion: 'add', text: '1'}, {ancho: '1', accion: 'add', text: '2'}, {ancho: '1', accion: 'add', text: '3'}],
    [{ancho: '1', accion: 'add', text: '4'}, {ancho: '1', accion: 'add', text: '5'}, {ancho: '1', accion: 'add', text: '6'}],
    [{ancho: '1', accion: 'add', text: '7'}, {ancho: '1', accion: 'add', text: '8'}, {ancho: '1', accion: 'add', text: '9'}],
    [{ancho: '1', accion: 'add', text: '.'}, {ancho: '1', accion: 'add', text: '0'}, {ancho: '1', accion: 'delete', text: 'delete'}],
    [{ancho: '3', accion: 'enter', text: 'Aceptar'}],
    [{ancho: '3', accion: 'suprimir', text: 'Eliminar'}]
  ];
  rut = [
    [{ancho: '1', accion: 'add', text: '1'}, {ancho: '1', accion: 'add', text: '2'}, {ancho: '1', accion: 'add', text: '3'}],
    [{ancho: '1', accion: 'add', text: '4'}, {ancho: '1', accion: 'add', text: '5'}, {ancho: '1', accion: 'add', text: '6'}],
    [{ancho: '1', accion: 'add', text: '7'}, {ancho: '1', accion: 'add', text: '8'}, {ancho: '1', accion: 'add', text: '9'}],
    [{ancho: '1', accion: 'add', text: 'K'}, {ancho: '1', accion: 'add', text: '0'}, {ancho: '1', accion: 'delete', text: 'delete'}],
    [{ancho: '3', accion: 'enter', text: 'Aceptar'}],
    // [{ancho: '3', accion: 'suprimir', text: 'Eliminar'}]
  ];

  alphaNumerico = [
    [
      {ancho: '1', accion: 'add', text: '1'},
      {ancho: '1', accion: 'add', text: '2'},
      {ancho: '1', accion: 'add', text: '3'},
      {ancho: '1', accion: 'add', text: '4'},
      {ancho: '1', accion: 'add', text: '5'},
      {ancho: '1', accion: 'add', text: '6'},
      {ancho: '1', accion: 'add', text: '7'},
      {ancho: '1', accion: 'add', text: '8'},
      {ancho: '1', accion: 'add', text: '9'},
      {ancho: '1', accion: 'add', text: '0'},
    ],
    [
      {ancho: '1', accion: 'add', text: 'q'},
      {ancho: '1', accion: 'add', text: 'w'},
      {ancho: '1', accion: 'add', text: 'e'},
      {ancho: '1', accion: 'add', text: 'r'},
      {ancho: '1', accion: 'add', text: 't'},
      {ancho: '1', accion: 'add', text: 'y'},
      {ancho: '1', accion: 'add', text: 'u'},
      {ancho: '1', accion: 'add', text: 'i'},
      {ancho: '1', accion: 'add', text: 'o'},
      {ancho: '1', accion: 'add', text: 'p'},
    ],
    [
      {ancho: '1', accion: 'add', text: 'a'},
      {ancho: '1', accion: 'add', text: 's'},
      {ancho: '1', accion: 'add', text: 'd'},
      {ancho: '1', accion: 'add', text: 'f'},
      {ancho: '1', accion: 'add', text: 'g'},
      {ancho: '1', accion: 'add', text: 'h'},
      {ancho: '1', accion: 'add', text: 'j'},
      {ancho: '1', accion: 'add', text: 'k'},
      {ancho: '1', accion: 'add', text: 'l'},
      {ancho: '1', accion: 'add', text: 'ñ'},
    ],
    [
      {ancho: '2', accion: 'upper', text: 'UP'},
      {ancho: '1', accion: 'add', text: 'z'},
      {ancho: '1', accion: 'add', text: 'x'},
      {ancho: '1', accion: 'add', text: 'c'},
      {ancho: '1', accion: 'add', text: 'v'},
      {ancho: '1', accion: 'add', text: 'b'},
      {ancho: '1', accion: 'add', text: 'n'},
      {ancho: '1', accion: 'add', text: 'm'},
      {ancho: '1', accion: 'delete', text: '<'},
    ],
    [
      {ancho: '2', accion: 'enter', text: 'Aceptar'},
      {ancho: '6', accion: 'space', text: 'Espacio'},
      {ancho: '2', accion: 'suprimir', text: 'Eliminar'}
    ]
  ];

  alphaNumericoM = [
    [
      {ancho: '1', accion: 'add', text: '1'},
      {ancho: '1', accion: 'add', text: '2'},
      {ancho: '1', accion: 'add', text: '3'},
      {ancho: '1', accion: 'add', text: '4'},
      {ancho: '1', accion: 'add', text: '5'},
      {ancho: '1', accion: 'add', text: '6'},
      {ancho: '1', accion: 'add', text: '7'},
      {ancho: '1', accion: 'add', text: '8'},
      {ancho: '1', accion: 'add', text: '9'},
      {ancho: '1', accion: 'add', text: '0'},
    ],
    [
      {ancho: '1', accion: 'add', text: 'Q'},
      {ancho: '1', accion: 'add', text: 'W'},
      {ancho: '1', accion: 'add', text: 'E'},
      {ancho: '1', accion: 'add', text: 'R'},
      {ancho: '1', accion: 'add', text: 'T'},
      {ancho: '1', accion: 'add', text: 'Y'},
      {ancho: '1', accion: 'add', text: 'U'},
      {ancho: '1', accion: 'add', text: 'I'},
      {ancho: '1', accion: 'add', text: 'O'},
      {ancho: '1', accion: 'add', text: 'P'},
    ],
    [
      {ancho: '1', accion: 'add', text: 'A'},
      {ancho: '1', accion: 'add', text: 'S'},
      {ancho: '1', accion: 'add', text: 'D'},
      {ancho: '1', accion: 'add', text: 'F'},
      {ancho: '1', accion: 'add', text: 'G'},
      {ancho: '1', accion: 'add', text: 'H'},
      {ancho: '1', accion: 'add', text: 'J'},
      {ancho: '1', accion: 'add', text: 'K'},
      {ancho: '1', accion: 'add', text: 'L'},
      {ancho: '1', accion: 'add', text: 'Ñ'},
    ],
    [
      {ancho: '2', accion: 'low', text: 'LOW'},
      {ancho: '1', accion: 'add', text: 'Z'},
      {ancho: '1', accion: 'add', text: 'X'},
      {ancho: '1', accion: 'add', text: 'C'},
      {ancho: '1', accion: 'add', text: 'V'},
      {ancho: '1', accion: 'add', text: 'B'},
      {ancho: '1', accion: 'add', text: 'N'},
      {ancho: '1', accion: 'add', text: 'M'},
      {ancho: '1', accion: 'delete', text: '<'},
    ],
    [
      {ancho: '2', accion: 'enter', text: 'Aceptar'},
      {ancho: '6', accion: 'space', text: 'Espacio'},
      {ancho: '2', accion: 'suprimir', text: 'Eliminar'}
    ]
  ];

  alphaEmail = [
    [
      {ancho: '1', accion: 'add', text: '1'},
      {ancho: '1', accion: 'add', text: '2'},
      {ancho: '1', accion: 'add', text: '3'},
      {ancho: '1', accion: 'add', text: '4'},
      {ancho: '1', accion: 'add', text: '5'},
      {ancho: '1', accion: 'add', text: '6'},
      {ancho: '1', accion: 'add', text: '7'},
      {ancho: '1', accion: 'add', text: '8'},
      {ancho: '1', accion: 'add', text: '9'},
      {ancho: '1', accion: 'add', text: '0'},
    ],
    [
      {ancho: '1', accion: 'add', text: 'q'},
      {ancho: '1', accion: 'add', text: 'w'},
      {ancho: '1', accion: 'add', text: 'e'},
      {ancho: '1', accion: 'add', text: 'r'},
      {ancho: '1', accion: 'add', text: 't'},
      {ancho: '1', accion: 'add', text: 'y'},
      {ancho: '1', accion: 'add', text: 'u'},
      {ancho: '1', accion: 'add', text: 'i'},
      {ancho: '1', accion: 'add', text: 'o'},
      {ancho: '1', accion: 'add', text: 'p'},
    ],
    [
      {ancho: '1', accion: 'add', text: 'a'},
      {ancho: '1', accion: 'add', text: 's'},
      {ancho: '1', accion: 'add', text: 'd'},
      {ancho: '1', accion: 'add', text: 'f'},
      {ancho: '1', accion: 'add', text: 'g'},
      {ancho: '1', accion: 'add', text: 'h'},
      {ancho: '1', accion: 'add', text: 'j'},
      {ancho: '1', accion: 'add', text: 'k'},
      {ancho: '1', accion: 'add', text: 'l'},
      {ancho: '1', accion: 'add', text: 'ñ'},
    ],
    [
      {ancho: '1', accion: 'add', text: 'z'},
      {ancho: '1', accion: 'add', text: 'x'},
      {ancho: '1', accion: 'add', text: 'c'},
      {ancho: '1', accion: 'add', text: 'v'},
      {ancho: '1', accion: 'add', text: 'b'},
      {ancho: '1', accion: 'add', text: 'n'},
      {ancho: '1', accion: 'add', text: 'm'},
      {ancho: '1', accion: 'add', text: '.'},
      {ancho: '1', accion: 'add', text: '_'},
      {ancho: '1', accion: 'add', text: '@'},
    ],
    [
      {ancho: '2', accion: 'caracter', text: '#+='},
      {ancho: '3', accion: 'enter', text: 'Aceptar'},
      {ancho: '3', accion: 'suprimir', text: 'Eliminar'},
      {ancho: '2', accion: 'delete', text: '<'},
    ]
  ];

  caracteres = [
    [
      {ancho: '1', accion: 'add', text: '`'},
      {ancho: '1', accion: 'add', text: '|'},
      {ancho: '1', accion: 'add', text: '{'},
      {ancho: '1', accion: 'add', text: '}'},
      {ancho: '1', accion: 'add', text: '?'},
      {ancho: '1', accion: 'add', text: '%'},
      {ancho: '1', accion: 'add', text: '^'},
      {ancho: '1', accion: 'add', text: '*'},
      {ancho: '1', accion: 'add', text: '/'},
      {ancho: '1', accion: 'add', text: "'"},
    ],
    [
      {ancho: '1', accion: 'add', text: '$'},
      {ancho: '1', accion: 'add', text: '!'},
      {ancho: '1', accion: 'add', text: '~'},
      {ancho: '1', accion: 'add', text: '&'},
      {ancho: '1', accion: 'add', text: '='},
      {ancho: '1', accion: 'add', text: '#'},
      {ancho: '1', accion: 'add', text: '['},
      {ancho: '1', accion: 'add', text: ']'},
      {ancho: '1', accion: 'add', text: '-'},
      {ancho: '1', accion: 'add', text: '+'},
    ],
    [
      {ancho: '2', accion: 'email', text: 'ABC'},
      {ancho: '3', accion: 'add', text: '@hotmail.com'},
      {ancho: '3', accion: 'add', text: '@gmail.com'},
      {ancho: '1', accion: 'add', text: '.com'},
      {ancho: '2', accion: 'delete', text: '<'},
    ],
    [
      {ancho: '5', accion: 'enter', text: 'Aceptar'},
      {ancho: '5', accion: 'suprimir', text: 'Eliminar'},
    ]
  ];

  constructor(
    private modalService: ModalTecladoService,
    private emitTecladoService: EmitTecladoService,
  ) { }

  ngOnInit(): void {
    this.subscripcion = this.emitTecladoService.model.subscribe(data => {
      this.model = data.input;
      this.texto.setValue(data.texto);
      this.desde = this.texto.value.length;
      this.max = data.max;
      this.tipo = data.tipo;

      switch (data.teclado) {
        case 'numerico':
          this.teclas = this.numerico;
          break;
        case 'rut':
          this.teclas = this.rut;
          break;
        case 'alphaNumerico':
          this.teclas = this.alphaNumerico;
          break;
        case 'decimal':
          this.teclas = this.decimal;
          break;
        case 'alphaEmail':
          this.teclas = this.alphaEmail;
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscripcion.unsubscribe();
  }

  setNgModel(accion, texto): void {
    switch (accion) {
      case 'add':
        if (this.texto.value.length >= this.max) {
          // mensaje u otra accion
        } else {
          const inicio = this.texto.value.substring(0, this.desde);
          const fin = this.texto.value.substring(this.desde, this.texto.value.length);
          this.texto.setValue(inicio + texto + fin);
        }
        break;
      case 'enter':
        this.modalService.closeModal();
        break;
      case 'delete':
        this.texto.setValue(this.texto.value.substring(0, this.texto.value.length - 1));
        break;
      case 'suprimir':
        this.texto.setValue('');
        break;
      case 'upper':
        this.teclas = this.alphaNumericoM;
        break;
      case 'low':
        this.teclas = this.alphaNumerico;
        break;
      case 'space':
        if (this.texto.value.length >= this.max) {
          // mensaje u otra accion
        } else {
          this.texto.setValue(this.texto + ' ');
        }
        break;
      case 'caracter':
        this.teclas = this.caracteres;
        break;
      case 'email':
        this.teclas = this.alphaEmail;
        break;
    }
    this.desde = (this.texto.value.length === this.desde + 1) ? this.texto.value.length : this.desde;
    this.emitTecladoService.enviarDataInput({ input: this.model, texto: this.texto, accion });
  }

  foco(event): void {
    this.desde = event.target.selectionStart;
  }
}

<!-- main app container -->
<div class="container-fluid overflow-auto" style="height: 65vh!important;">
    <h5 class="card-header" class="text-secondary">{{title}} Oficina</h5>
    <div class="card-body">
        <form [formGroup]="registerForm" class="text-secondary" *ngIf="registerForm">
            <div class="form-group">
                <label for="exampleInputZone">ID Oficina</label>
                <input type="number" [ngClass]="{'is-invalid': submitted && (registerForm.get('idOf').errors)}" formControlName="idOf" class="form-control">
                <div *ngIf="submitted && (registerForm.get('idOf').errors)">
                    <small class="msg-error" *ngIf="registerForm.get('idOf').errors">Ingrese un ID</small>
                </div>
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Nombre</label>
                <input type="text" [ngClass]="{'is-invalid': submitted && (registerForm.get('sOf').errors)}" formControlName="sOf" class="form-control" minlength="2">
                <div *ngIf="submitted && (registerForm.get('sOf').errors)">
                    <small class="msg-error" *ngIf="registerForm.get('sOf').errors">Ingrese un nombre de oficina</small>
                </div>
            </div>
            <div class="form-group">
                <label for="exampleInputZone">Email</label>
                <input type="email" multiple [ngClass]="{'is-invalid': submitted && (registerForm.get('sCorreo').errors)}" formControlName="sCorreo" class="form-control">
                <div *ngIf="submitted && (registerForm.get('sCorreo').errors)">
                    <small class="msg-error" *ngIf="registerForm.get('sCorreo').errors && !registerForm.get('sCorreo').errors?.pattern">Ingrese un email</small>
                </div>
                <div *ngIf="registerForm.get('sCorreo').errors?.pattern">
                    <small class="msg-error" *ngIf="registerForm.get('sCorreo').errors?.pattern">Ingrese un email valido</small>
                </div>
            </div>
            <!-- <div class="form-group">
                <label for="exampleInputZone">Estado</label><br>
                <select class="form-control" [ngClass]="{'is-invalid': submitted && registerForm.get('hab').errors}" formControlName="hab">
                    <option [ngValue]="null" [disabled]="true" >Selecciona</option>
                    <option [ngValue]="true">Habilitado</option>
                    <option [ngValue]="false">Deshabilitado</option>
                </select>
                <div *ngIf="submitted && registerForm.get('hab').errors">
                    <small class="msg-error">Seleccione un estado</small>
                </div>
            </div>      -->
        </form>
    </div>
    <form>
        <div class="text-center">
            <button class="btn btn-primary mr-1" type="submit" (click)="agregarOficina()">GUARDAR</button>
            <button class="btn btn-secondary" (click)="cerrarModal()">CANCELAR</button>
        </div>
        <br>
    </form>
</div>
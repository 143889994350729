import { Component, OnInit,OnDestroy } from '@angular/core';
import { TarjetaLockerService } from '../../../../core/services/http/tarjeta-locker.service';
import { SweetAlertService } from '../../../../core/services/sweet-alert/sweet-alert.service';
import { PruebasService } from '../../../../core/services/http/pruebas.service';

@Component({
  selector: 'app-locker',
  templateUrl: './locker.component.html',
  styleUrls: ['./locker.component.scss']
})
export class LockerComponent implements OnInit, OnDestroy {

  Locker: any[];
  pedidos: any[];
  casilleroHover: boolean;
  ventanaCasH = {};

  oficina;
  ConsulOfi=true;

  constructor(private TarjetaLockerService: TarjetaLockerService, 
              private SweetAlertService: SweetAlertService, 
              private PruebasService: PruebasService) 
              { }

  ngOnInit(): void {
    // this.TarjetaLockerService.Conectar();
    var usuario = JSON.parse(localStorage.getItem("usulogeado"));
    this.oficina = usuario["idOficina"];
    localStorage.setItem('ofiselect',usuario["idOficina"])
    this.CargarLocker();
    this.casilleroHover = false;
    this.consultarCambioOficina();
    
  }
  
  hovercas(index: any) {
    this.Locker.forEach((columnas: { casilleros: any[]; }) => {
       console.log('columnas hover', columnas);
      columnas["Casilleros"].forEach((casillero: { id: string; tama: string; }) => {
        this.pedidos.forEach((estadocas: { idCasillero?: string; }) => {
          if (casillero.id == estadocas.idCasillero  && casillero.id ==index && casillero.tama != "admin") {
            this.casilleroHover = true;
            console.log("MARCADO: ",estadocas);
            this.ventanaCasH = estadocas;
          }
        });
      });

    });
    
  }
  
  leavecas(index: any) {
    this.casilleroHover = false;
    // console.log("MARCADO 2:", index);
    // console.log("MARCADO 3:",this.pedidos[index-1]);

  }
  // 1°
  async CargarLocker() {
    this.SweetAlertService.swalLoading();
    var resplocker= await this.TarjetaLockerService.DibujoCasillero(this.oficina);
    this.Locker = JSON.parse(resplocker["data"][0]["estructura"])
    console.log('Array de muchos lockers',this.Locker);
    var idlocker = resplocker["data"][0]["idLocker"]
    var respuesta = await this.PruebasService.Listacasilleros(idlocker);
    respuesta = respuesta["data"];
    this.pedidos = respuesta

    this.Locker.forEach(columnas => {
      columnas.Casilleros.forEach(casillero => {
        this.pedidos.forEach(estadocas => {
          if (estadocas["idCasillero"] == casillero.id && estadocas["sEstado"] == "cargado") {
            casillero.ocupado = true;
          }
          if (estadocas["idCasillero"] == casillero.id && estadocas["observacion"] != null) {
            casillero.exp = true;
          }
        });
      });
    })
  }

  async Liberar()
  {
    var reqOficina = {
      "rutUsuario": this.ventanaCasH["sDniUsu"],
      "idCasillero": this.ventanaCasH["idCasillero"],
      "idLocker": this.ventanaCasH["idLocker"],
      "idOficina": this.ventanaCasH["idOficina"]
    }
    console.log("DATOS A EDITAR: ", reqOficina);
    var respl = await this.SweetAlertService.swalConfirmLiberar("Si");
    if (respl) {
      this.SweetAlertService.swalLoading();
      var resp = await this.PruebasService.LiberarCasillero(reqOficina);
      if (resp["status"]) {
        await this.SweetAlertService.swalUsuarioActualizado("Éxito","Se ha liberado el casillero");
        await this.CargarLocker();
      }
      else {
        this.SweetAlertService.swalError("No se pudieron realizar los cambios. Intente nuevamente");
      }
      this.SweetAlertService.swalClose();  
   } 
  }

  async consultarCambioOficina(){
    if (this.ConsulOfi == true ) {
      if (this.oficina != localStorage.getItem("ofiselect") ) {
        this.oficina = localStorage.getItem("ofiselect");
        var resp = await this.CargarLocker();
        this.SweetAlertService.swalClose();
      }
      setTimeout(() => {
        this.consultarCambioOficina();
      }, 1100);
      
    }
  }

  ngOnDestroy(): void {
    this.ConsulOfi = false;
  }



}

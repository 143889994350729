import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rutFormate'
})
export class FormatoRutPipe implements PipeTransform {

  transform(value: any): any {
    return value
      .toString()
      .replace(/(\.\d+)|\B(?=(\d{3})+(?!\d))/g, (g1: any) => {
        return g1 || '.';
      });
  }

}

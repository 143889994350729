<!-- Content Wrapper. Contains page content -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-auto">
          <h1 class="m-0 pl-4 subtitle">Resumen Locker</h1>
        </div>
        <!-- /.col -->
        <div class="col-sm-6 mt-1 border-left border-right">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a href="#" [routerLink]="['/MenuOpciones']">Oxxo</a></li>
            <li *ngIf="rolsesion != 3" class="breadcrumb-item active">Resumen Locker</li>
            <li *ngIf="rolsesion == 3" class="breadcrumb-item active">Busqueda de Cliente</li>
          </ol>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <section class="content " *ngIf="rolsesion != 3">
    <div class="container-fluid d-flex">
      <!-- Resumen  -->
      <!-- <div class="row d-flex col-4 h-50">
        <div class="col-lg-12 col-sm-12 col-12">
          
          <div class="small-box bg-info">
            <div class="inner">
              <h6>Total casilleros</h6>
              <h3 class="num-popp">{{total}}</h3>

              <p>.</p>
            </div>

            <div class="icon">
              <div class="ico-box"> <img src="/assets/img/herramientas.svg">
               
              </div>
            </div>

          </div>
        </div>
        
        <div class="col-lg-12 col-sm-12 col-12">
         
          <div class="small-box bg-info ">
            <div class="inner">
              <h6>Casilleros Disponibles</h6>
              <h3 class="num-popp">{{Disponibles}}</h3>
              <p>El día de hoy</p>
            </div>
            <div class="icon">
              <div class="ico-box"> <img src="/assets/img/herramientas.svg">

              </div>
            </div>


          </div>
        </div>
        
        <div class="col-lg-12 col-sm-12 col-12">
          
          <div class="small-box bg-info ">
            <div class="inner">
              <h6>Casilleros Usados</h6>
              <h3 class="num-popp">{{Usados}}</h3>

              <p>.</p>
            </div>

            <div class="icon">
              <div class="ico-box"> <img src="/assets/img/herramientasDanadas.svg">

              </div>
            </div>


          </div>
        </div>
        
        <div class="col-lg-12 col-sm-12 col-12">
         
          <div class="small-box bg-info ">
            <div class="inner">
              <h6>Pedidos Vencidos</h6>
              <h3 class="num-popp">{{Expirados}}</h3>
              <p>En general</p>
            </div>
            <div class="icon">
              <div class="ico-box"> <img src="/assets/img/herramientasDanadas.svg">
              </div>
            </div>
          </div>
        </div>
        
      </div> -->

      <!-- Main row -->
      <div class="row col-12">
        <!-- Left col -->
        <section class="col-lg-12  connectedSortable">
          <!-- Custom tabs (Charts with tabs)-->
          <div class="card box-success box">
            <!-- /.card-header -->
            <div class="card-body">
              <!-- <app-locker></app-locker> -->
              <app-safe></app-safe>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
          <!-- /.card -->
        </section>
        <!-- /.Left col -->
        <!-- right col (We are only adding the ID to make the widgets sortable)-->
        <!-- right col -->
      </div>
      <!-- /.row (main row) -->
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- /.content -->

  <!-- Busqueda cliente -->
  <section *ngIf="rolsesion == 3">
    <form [formGroup]="registerForm" class="text-secondary" *ngIf="registerForm" (ngSubmit)="onSubmit()">
      <div class="container">
        <div class="form-group">
          <label for="dniUsu">Rut CLiente </label>
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="dniUsu"><i class="fas fa-id-card"></i></span>
            </div>
            <input type="text" class="form-control" name="dniUsu" formControlName="dniUsu"
              [ngClass]="{ 'is-invalid': formDirtyRequire('dniUsu')}" (keyup)="setRut()">
          </div>
          <div *ngIf="!rutValid && registerForm.value.dniUsu.length > 7">
            <div *ngIf="registerForm.value.dniUsu.length > 12">
              <small class="text-danger">
                Ingrese un Rut valido con un maximo de 99.999.999-9
              </small>
            </div>
          </div>
          <div *ngIf="!rutValid && registerForm.value.dniUsu.length > 7">
            <div *ngIf="registerForm.value.dniUsu.length < 8">
              <small class="text-danger">
                Por favor ingresa un rut válido con un minimo de 8 digitos
              </small>
            </div>
          </div>

          <div *ngIf="!rutValid && registerForm.value.dniUsu.length > 7">
            <small class="text-danger">
              Formato incorrecto por favor ingresa un rut válido
            </small>
          </div>

          <div class="text-center mt-5">
            <button class="btn btn-primary mr-1" type="submit"
                [disabled]="!rutValid">
                Buscar
            </button>
        </div>

        </div>
      </div>
    </form>
  </section>
</div>
<!-- /.content-wrapper -->
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { City, Oficinas, Oficina } from '../../../customer';
import { PruebasService } from '../../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subject } from 'rxjs';
import {DropdownModule} from 'primeng/dropdown';
import * as moment from 'moment';

@Component({
  selector: 'app-oficinas-modal',
  templateUrl: './oficinas-modal.component.html',
  styleUrls: ['./oficinas-modal.component.scss']
})
export class OficinasModalComponent implements OnInit {

  registerForm: FormGroup;
  submitted: boolean = false;
  datosInterface;
  onClose: Subject<boolean>;
  //@Input() regiones;
  title: string;
  cities: City[];
  selectedCity: City;
  moment = moment;
  ListaOficinas: [] = [];

  @Input() public Oficina: Oficina;
  constructor(
    private dropDownModule: DropdownModule,
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private PruebasService: PruebasService,
    private SweetAlertService: SweetAlertService,
    private router: Router) {  }

  ngOnInit(): void {
    this.iniciarDato()
    console.log(this.Oficina);
    this.onClose = new Subject();
  }

  get f() { return this.registerForm.controls; }

  cerrarModal() {
    this.onClose.next(true);
    this.modalService.closeModal();
  }

  async iniciarDato() {
    this.registerForm = this.formBuilder.group({
      idOf: ['', Validators.required],
      sOf: ['', Validators.required],
      sCorreo: ['', [ Validators.required, Validators.pattern(/^[\w]{1,}[\w.+-]{0,}@[\w-]{1,}([.][a-zA-Z]{2,}|[.][\w-]{2,}[.][a-zA-Z]{2,})$/)]]
    });
  }

  obtenerdatos() {
    let longitud = 5;
    this.datosInterface = {

      "idO": this.registerForm.value.idOf,
      "codigo": this.registerForm.value.idOf.toString().padStart(longitud, '0'),
      "correo": this.registerForm.value.sCorreo,
      "sOficina": this.registerForm.value.sOf

    }
    

  }
  
  async agregarOficina() {

    this.submitted = true;
    if(this.registerForm.invalid){return;}

    await this.obtenerdatos();
    console.log(this.datosInterface);
    this.SweetAlertService.swalLoading();
    let datosRetornados = await this.PruebasService.AgregarOficinasMethanext(this.datosInterface)
    console.log('RESPONSE: ', datosRetornados);
    if (datosRetornados.status === true) {
      await this.SweetAlertService.swalSuccess("Éxito","¡Oficina creada!");
      this.SweetAlertService.swalClose();
      this.cerrarModal();
    }
    else {
      await this.SweetAlertService.swalError("¡No se pudo crear la oficina, intente nuevamente!");
    }
  }

  editarOficina():any{
    console.log(this.Oficina);
  }

}

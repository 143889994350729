import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataProcessService } from '../../../core/services/http/data-process.service';
import { PruebasService } from '../../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() step: string;
  ofiselect;
  usuario;
  rolsesion;
  urgencia = false;
  ayuda = false;
  sitio = "";
  data: any;
  localData: any;
  oficinas: any[];
  constructor(
    private router:Router,
    private dataProcessService: DataProcessService,
    private pService: PruebasService,
    private SweetAlertService:SweetAlertService
    ) { }

  ngOnInit(): void {
    this.validarconexion();
    this.rolsesion = localStorage.getItem("rolsesion");
    this.data = this.dataProcessService.getDataProcess();
    this.localData = localStorage.getItem('rut');
    this.oficinas;
    this.buscar();
    this.usuario =JSON.parse(localStorage.getItem("usulogeado"));
    // console.log("RUT: ", this.data["rut"]);
    // console.log("RUT LOCAL STORAGE: ", this.localData);
  }

  async validarconexion(){

    console.log(localStorage.getItem('tokenUser'));
    var salir = localStorage.getItem('tokenUser');
    if (salir == "null") {
      console.log("entrro");
      this.router.navigate(['/']);
      // var resp = await this.SweetAlertService.swalError("Debe validarse para poder acceder a sistema");

    }
  }

  async buscar(){

    // console.log(this.data);
    let resp = await this.pService.obtenerSucursales()
    if (resp['data'] === '') {
      return;
    }
    const ofizero = {
      "idOficina": 0,
      "sCodOfi": "cero",
      "iHorVencCarga": 0,
      "iHorVencRetiro": 0,
      "sCorreo": "",
      "sOficina": "Todas",
      "casilleros": [],
      "usuarios": []
    }
    this.oficinas = [];
    this.oficinas.push(ofizero);
    resp.data.forEach(element => {
      this.oficinas.push(element);
    });
   this.oficinas.concat(resp.data);
    
    this.oficinas.forEach(element => {
      if (element["idOficina"] == this.usuario["idOficina"]) {
        this.ofiselect = element;
      }
    });
  }

  setoficina(ofi){
console.log(ofi);
console.log(this.ofiselect);

localStorage.setItem('ofiselect',ofi["idOficina"])


  }

  RenviarCorreo(){
    this.router.navigate(['reenviar-correo']);
  }

  redireccionReporteriaCierre()
  {
    this.router.navigate(['cierre']);
  }
  redireccionReporteriaLog()
  {
    this.router.navigate(['reporte-log']);
  }

  redireccionParaPedido()
  {
    this.router.navigate(['NuevoPedido']);
  }
  Dashboard()
  {
    this.router.navigate(['MenuOpciones']);
  }
  redireccionParaRetiro()
  {
    this.router.navigate(['RetirarPedido'],{ queryParams: { 'idcasillero': '5', 'idLocker': '5', 'idOficina':'5' } });
  }
  redireccionTablaPedidos()
  {
    this.router.navigate(['TablaPedido']);
  }
  redireccionTablaZona()
  {
    this.router.navigate(['TablaZona']);
  }
  redireccionTablaRegiones()
  {
    this.router.navigate(['TablaRegiones']);
  }
  redireccionTablaComunas()
  {
    this.router.navigate(['TablaComunas']);
  }
  redireccionTablaOficinas()
  {
    this.router.navigate(['TablaOficinas']);
  }
  redireccionTablaBodegas()
  {
    this.router.navigate(['TablaBodegas']);
  }
  redireccionTablaClientes()
  {
    this.router.navigate(['TablaClientes']);
  }
  redireccionTablaUsuarios()
  {
    this.router.navigate(['TablaUser']);
  }
  redireccionTablaLockers()
  {
    this.router.navigate(['TablaLockers']);
  }
  redireccionTablaCasilleros()
  {
    this.router.navigate(['TablaCasilleros']);
  }
  redireccionTablaUsuariosOfi()
  {
    this.router.navigate(['TablaUsuariosOfi']);
  }
  redireccionTipoElemento()
  {
    this.router.navigate(['TipoElemento']);
  }
  redireccionElemento()
  {
    this.router.navigate(['Elemento']);
  }
  redireccionHome()
  {
    this.router.navigate(['']);
  }

  //MethaNext
  redireccionMasivaElementos()
  {
    this.router.navigate(['CargaMasivaElementos']);
  }
  redireccionMasivaUsuarios()
  {
    this.router.navigate(['CargaMasivaUsuarios']);
  }

}

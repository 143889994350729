import { Component, OnInit, ViewChild } from '@angular/core';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { IdOficinaNew, Numeros, Oficina, Oficinas} from '../../customer';
import { ModalService } from '../../core/services/modals/modal.service'
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormsModule } from '@angular/forms';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OficinasModalComponent } from './oficinas-modal/oficinas-modal.component';
import { ConfigService } from '../../core/services/config/config.service';
//exportar a excel
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
//exportar a pdf
import {jsPDF} from 'jspdf';
import autoTable from 'jspdf-autotable'

@Component({
  selector: 'app-admi-oficinas',
  templateUrl: './admi-oficinas.component.html',
  styleUrls: ['./admi-oficinas.component.scss']
})
export class AdmiOficinasComponent implements OnInit {

   oficina: Oficina;
   oficinasV2: [] = []; // almacena el total de oficinas
   myGroup: FormGroup;
   datosInterface: Oficinas;
   selectedProducts: Oficinas[];
   modalRef: BsModalRef;
   loading;
   numeros = [];
   sesionrol;
   // exportación
  columns: any[] = [];
  exportColumns: any[];
  cols: any[];
  fileName= 'Oficinas.xlsx';
  filteredValues: any;

  constructor(private PruebasService: PruebasService, private modalService: ModalService, private Router: Router, private SweetAlertService: SweetAlertService, private configService: ConfigService) { }

  async ngOnInit() {
    this.sesionrol = localStorage.getItem('rolsesion')
    this.iniciarDatos()
    this.delUnoAlCincuenta();
    this.loading = true;
    this.llenarTabla();
    this.loading = false;
    
 }
  volver() {
    this.Router.navigate(['MenuOpciones']);
  }


  iniciarDatos()
  {
    this.myGroup = new FormGroup({
      idOficina: new FormControl(),
      sCorreo: new FormControl(),
      sOficina: new FormControl(),
      bEstado: new FormControl(),
      desde: new FormControl(),
      hasta: new FormControl()
    }); 
  }
  
  async editarOficina(datos) {
    console.log(datos);
    var reqOficina = {
      "idO": datos.idOficina,
      "codigo": datos.sCodOfi,
      "correo": datos.sCorreo,
      "sOficina": datos.sOficina
    }
  //this.exportColumns = this.cols.map(col => ({title: col.header, dataKey: col.field}));
    console.log("DATOS A EDITAR: ", reqOficina);
    this.SweetAlertService.swalLoading();
    var resp = await this.PruebasService.ActualizarOficinaMethanext(reqOficina);
    if (resp["status"]) {
      await this.llenarTabla();
      await this.SweetAlertService.swalUsuarioActualizado("Éxito","Se ha modificado la oficina");
    }
    else {
      this.SweetAlertService.swalError("No se pudieron realizar los cambios. Intente nuevamente");
    }
  }

  async EliminarCliente(datos:IdOficinaNew) {
    var confirma = await this.SweetAlertService.swalEliminar("¿Desea Eliminar la oficina?","No se podrá revertir esta acción")
  if (confirma == true ) {
    console.log(datos);
    var reqCliente = datos.idOficina;
    this.SweetAlertService.swalLoading();
    var resp = await this.PruebasService.EliminarOficinaMethanext(reqCliente);
    if (resp["status"]) {
      await this.llenarTabla();
      this.SweetAlertService.swalUsuarioEliminado("Éxito","Se ha eliminado correctamente la oficina");
    }
    else {
      this.SweetAlertService.swalError("Error, intente nuevamente...");
      }
    }
    
  }

  async llenarTabla()
  {
    this.SweetAlertService.swalLoading();
    let response = await this.PruebasService.TraerOficinas();
    if (response['status']) {
      this.oficinasV2 = response['data'];
      this.filteredValues = this.oficinasV2;
      // console.log("Oficinas:", this.oficinasV2);
    }
    this.SweetAlertService.swalClose();
  }

  delUnoAlCincuenta()
  {
    let max = 6;

    for(let i = 1;i <= (max * 10);i++){
      this.numeros.push({"num":i});
    
    }
    //console.log("NUMERO: ",this.numeros);
  }

  // editarOficina(dato:Oficina){
  //   const {
  //     casilleros,    
  //     iHorVencCarga ,
  //     iHorVencRetiro,
  //     idOficina,
  //     sCodOfi,       
  //     sCorreo,       
  //     sOficina } = dato
  //   console.log(this.oficina = dato)
  //   const opcion = {class: 'w-50', initialState: {title:'Editar', regiones: this.oficina}}
  //   this.modalRef = this.modalService.openModal(OficinasModalComponent, opcion);
  //   this.modalRef.content.onClose.subscribe((result) => {
  //     console.log('result: ', result);
      
  //   });
  // }

  Seleccionar()
  {
    const opcion = {class: 'w-50', initialState: {title:'Agregar',regiones: this.oficinasV2}}
    this.modalRef = this.modalService.openModal(OficinasModalComponent, opcion);
    this.modalRef.content.onClose.subscribe((result) => {
      console.log('result: ', result);
      // if(result){
      //   this.llenarTabla()
      // }
    });
  }

  onFilter(event, dt) { 
    this.filteredValues = event.filteredValue; 
  }

  //EXPORTAR A EXCEL (SE FILTRAN LOS DATOS A IMPRIMIR)
  exportExcel() {
    var datos = [];
    var toExport = [];
    if(this.filteredValues)
    {
      datos = this.filteredValues;
      for (let element of datos) {
      var obj = {
        'ID OFicina':element.sCodOfi,
        'Nombre': element.sOficina,
        'Email': element.sCorreo
        }
      toExport.push(obj);
      }
      import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(toExport);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "Oficinas");
      });
    }
    else
    {
      datos = this.oficinasV2;
      for (let element of datos) {
      var obj = {
        'ID OFicina':element.sCodOfi,
        'Nombre': element.sOficina,
        'Email': element.sCorreo
        }
      toExport.push(obj);
      }
      import("xlsx").then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(toExport);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, "Oficinas");
      });
    }
    
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  }

  //EXPORTAR A PDF
  exportPdf() {
    var datos = [];
    var toExport = [];
    datos = this.filteredValues;
    for (let element of datos) {
      var obj = {
        'ID OFicina':element.sCodOfi,
        'Nombre': element.sOficina,
        'Email': element.sCorreo
      }
      toExport.push(obj);
    }
     var doc = new jsPDF('p', 'pt');
     doc.text("Oficinas",50, 30);
      autoTable(doc, {columns: this.exportColumns,body: toExport})
      doc.save('Oficinas.pdf');
  }
}

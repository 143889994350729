import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { PruebasService } from '../http/pruebas.service';
import { login } from 'src/app/customer';
import { Auth } from 'src/app/modules/interfaces/interface';
import { SweetAlertService } from '../sweet-alert/sweet-alert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private subscriptions: Subscription[] = [];
  API_URL :any  =  this.configService.getConfig();
  public autenticado: boolean;
  headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  });

  constructor(
    private userIdle: UserIdleService,
    private router: Router,
    private configService: ConfigService,
    private PruebasService: PruebasService,
    private sweetAlertService: SweetAlertService,
    private httpClient: HttpClient
  ) {}

  async ValidaLogin(user: any, pass: any): Promise<any> {
    var headers = this.headers.set('Authorization', 'Bearer ' +localStorage.getItem('tokenUser'));
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['api_url']}/}`;
    try {
      // return await this.httpClient.get(url, { headers: this.headers }).toPromise();
      return {
        status: true,
        code: 200,
        message: 'OK'
      };
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }
  /**realiza el login del usuario */

  async login(request: any): Promise<any>{
    const API_URL = await this.configService.getConfig();
    const url = `${API_URL['BaseAPI']}Login/InicioSesion`;
    try {
      return await this.httpClient
        .post(url, request, { headers: this.headers })
        .toPromise();
    } catch (error) {
      return {
        status: false,
        code: 804,
        message: 'Error al ejecutar la petición.',
      };
    }
  }

  isLogged(): any {
    this.autenticado = JSON.parse(localStorage.getItem('usulogeado')) 
    console.log(this.autenticado);
    // if (this.autenticado) {
      
    //   return this.autenticado = true;
    // }
  }

  loginUsuario():any{
    let rut: string = localStorage.getItem('rut')
    console.log(rut);
    const url = `https://tsoxxo.totalpack.cl/api/Usuarios/GetUsuario?usuario=${rut}`;
    return this.httpClient.get<Auth>(`${url}`)
  }

  logout(): void {
    localStorage.clear();
    localStorage.setItem("Reiniciado","no");
    this.router.navigate(['/']);
    //window.location.reload();
    console.log('logout');
    
  }
  noAuth(): void {
    this.router.navigate(['/']);
    setTimeout(() => {
      this.sweetAlertService.infoToast('error', 'Usuario no autorizado.');
    }, 1000);
  }

  idleUser(): void {
    this.userIdle.startWatching();

    this.subscriptions.push(this.userIdle.onTimerStart().subscribe(count => {
      const eventList = [
        'click', 'mouseover', 'keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'scroll', 'keyup'
      ];
      eventList.map((event => {
        document.body.addEventListener(event, () => this.userIdle.resetTimer());
      }));
    }));

    this.subscriptions.push(this.userIdle.onTimeout().subscribe(() => {
      if (this.router.url !== '/') {
        this.userIdle.resetTimer();
        this.userIdle.stopTimer();
        this.userIdle.stopWatching();
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.logout();
      }
      else{
        window.location.reload();
      }
    }));
  }
}

<!-- <div class="container-fluid overflow-auto">
<button (click)="volver()">Volver</button>
    <h2>Zonas</h2>
    <p>A continuación, se muestran los pedidos con la busqueda</p> 
    <button type="submit" (click)="TablaUser()">Añadir...</button> --
  <button (click)="volver()">Volver</button>
  <h5>Admi Usuarios Oficina</h5>
  <p>Para modificar presione el nombre en la tabla.</p>
  <button type="submit" class="btn btn-primary btn-block" (click)="Seleccionar()">
    Registrar
  </button>
  <div *ngIf="usuariosOfi2">
    <p-table #dt1 [value]="usuariosOfi2" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10, 25, 50]" [loading]="loading" styleClass="p-datatable-customers p-datatable-gridlines"
      [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [globalFilterFields]="['idOficina','sDniUsu','password']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Palabra clave" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">ID Oficina</div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">Rut Usuario</div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">Contraseña</div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">Actualizar</div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-customer>
        <tr>
          <td>
            {{ customer.idOficina }}
          </td>
          <td>
            {{ customer.sDniUsu }}
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input" style="text-align: justify;">
                <input pInputText type="password" [(ngModel)]="customer.password2"/>
              </ng-template>
              <ng-template pTemplate="output">
                <div>
                  ****
                </div>
              </ng-template>
            </p-cellEditor>
          </td>
          <td (click)="traerdatos(customer)" class="pointer">
              Actualizar
            </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10">No hay Usuarios para mostrar.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <br />
  <br />
  <br />
  <br />
</div> -->
<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-auto">
          <h1 class="m-0 pl-4 subtitle">Módulo de Usuarios</h1>
        </div>
        <!-- /.col -->
        <div class="col-sm-6 mt-1 border-left border-right">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a href="#">Admin Usuarios</a></li>
            <li class="breadcrumb-item active">Usuarios</li>
          </ol>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">

      <!-- Main row -->
      <div class="row">
        <!-- Left col -->
        <section class="col-lg-12 pl-3 pr-3 connectedSortable">

          <div class="card  box">
            <div class="row p-3">
              <div class="col-12 col-md-8">
                <h5>Asignar Usuarios Oficina</h5>
              </div>
              <div class="col-12" *ngIf="sesionrol == 1">
                <button type="button" (click)="Seleccionar()" 
                        class="btn btn-block btn-success">
                  <i class="fas fa-cogs"></i>
                   Agregar Nuevos Usuarios
                </button><br><br>
              </div>
              <div class="col-12">
                <!-- table table-responsive table-hover mt-2 -->
                <div *ngIf="usuariosOfi2">
                  <p-table #dt1 [value]="usuariosOfi2" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[10, 25, 50]" [loading]="loading"
                    styleClass="p-datatable-customers p-datatable-gridlines" [paginator]="true"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [globalFilterFields]="['idOficina','sDniUsu','password']">
                    <ng-template pTemplate="caption">
                      <div class="p-d-flex">
                        Usuarios de Oficina
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar en Tabla" />
                        </span>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                      <tr>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">Nombre</div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">Rut Usuario</div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">Rol</div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">Oficina</div>
                        </th>
                        <th *ngIf="sesionrol == 1">
                          <div class="p-d-flex p-jc-between p-ai-center">Actualizar</div>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-customer>
                      <tr>
                        <td>
                          {{ customer.sNombreUsu }}
                        </td>
                        <td>
                          {{ customer.sDniUsu | rutFormate}}
                        </td>
                        <td>
                          {{customer.nombreRol}}
                        </td>
                        <td>
                          {{ customer.nombreOficina}}
                        </td>
                        <!-- <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input" style="text-align: justify;">
                              <input pInputText type="password" [(ngModel)]="customer.password2" />
                            </ng-template>
                            <ng-template pTemplate="output">
                              <div>
                                ****
                              </div>
                            </ng-template>
                          </p-cellEditor>
                        </td> -->
                        <td (click)="seleccionarUsuario(customer)" *ngIf="sesionrol == 1">
                          <i class="fas fa-edit" style="cursor: pointer;"></i>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="10">No hay Usuarios para mostrar.</td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>

          </div>

        </section>
        <!-- /.Left col -->
        <!-- right col (We are only adding the ID to make the widgets sortable)-->

        <!-- right col -->
      </div>
      <!-- /.row (main row) -->
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- /.content -->
</div>
<!-- <div class="container-fluid overflow-auto" style="height: 100vh!important;">
  <button (click)="volver()">Volver</button>
    <h2>Zonas</h2>
    <p>A continuación, se muestran los pedidos con la busqueda</p> 
    <button type="submit" (click)="TablaUser()">Añadir...</button> -
  <button (click)="volver()">Volver</button>
  <h5>Admi Lockers</h5>
  <p>Para modificar presione el nombre en la tabla.</p>
  <button type="submit" class="btn btn-primary btn-block" (click)="Seleccionar()">Registrar</button>
  <div *ngIf="lockersV2" style="height: 700px; position: relative; right: 20px;">

    <p-table #dt1 [value]="lockersV2" dataKey="id" [rows]="18" [showCurrentPageReport]="true"
      [rowsPerPageOptions]="[10,25,50]" [loading]="loading" styleClass="p-datatable-customers p-datatable-gridlines"
      [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      [globalFilterFields]=
      "['idL','idO','nPuertas','hab', 'sNomLocker','sDireccion','idComuna','sLatitud','sLongitud',
      'iLuhi','iLumi','iLuhf','iLumf',
      'iMahi','iMami','iMahf','iMamf',
      'iMihi','iMimi','iMihf','iMimf',
      'iJuhi','iJumi','iJuhf','iJumf',
      'iVihi','iVimi','iVihf','iVimf',
      'iSahi','iSami','iSahf','iSamf',
      'iDohi','iDomi','iDohf','iDomf']">
      <ng-template pTemplate="caption">
        <div class="p-d-flex">
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
              placeholder="Palabra clave" />
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              ID Locker
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Numero Puertas
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Estado
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Nombre de Locker
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Dirección
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Id Comuna
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Latitud
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Longitud
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Lunes
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Martes
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Miercoles
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Jueves
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Viernes
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Sábado
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Domingo
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Editar
            </div>
          </th>
          <th>
            <div class="p-d-flex p-jc-between p-ai-center">
              Habilitar/Deshabilitar
            </div>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-customer>
        <tr>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="customer.idLocker">
              </ng-template>
              <ng-template pTemplate="output">
                {{customer.idLocker}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="customer.iNumPuertas">
              </ng-template>
              <ng-template pTemplate="output">
                {{customer.iNumPuertas}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="customer.bHab">
              </ng-template>
              <ng-template pTemplate="output">
                {{customer.bHab}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="customer.sNomLocker">
              </ng-template>
              <ng-template pTemplate="output">
                {{customer.sNomLocker}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="customer.sDireccion">
              </ng-template>
              <ng-template pTemplate="output">
                {{customer.sDireccion}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="customer.idComuna">
              </ng-template>
              <ng-template pTemplate="output">
                {{customer.idComuna}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="customer.sLatitud">
              </ng-template>
              <ng-template pTemplate="output">
                {{customer.sLatitud}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="text" [(ngModel)]="customer.sLongitud">
              </ng-template>
              <ng-template pTemplate="output">
                {{customer.sLongitud}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="customer.iLuhi">
                <input pInputText type="number" [(ngModel)]="customer.iLumi">
              </ng-template>
              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="customer.iLuhf">
                <input pInputText type="number" [(ngModel)]="customer.iLumf">
              </ng-template>
              <ng-template pTemplate="output">
                {{customer.iLuhi}}:{{customer.iLumi}} a {{customer.iLuhf}}:{{customer.iLumf}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="customer.iMahi">
                <input pInputText type="number" [(ngModel)]="customer.iMami">
              </ng-template>
              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="customer.iMahf">
                <input pInputText type="number" [(ngModel)]="customer.iMamf">
              </ng-template>
              <ng-template pTemplate="output">
                {{customer.iMahi}}:{{customer.iMami}} a {{customer.iMahf}}:{{customer.iMamf}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="customer.iMihi">
                <input pInputText type="number" [(ngModel)]="customer.iMimi">
              </ng-template>
              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="customer.iMihf">
                <input pInputText type="number" [(ngModel)]="customer.iMimf">
              </ng-template>
              <ng-template pTemplate="output">
                {{customer.iMihi}}:{{customer.iMimi}} a {{customer.iMihf}}:{{customer.iMimf}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="customer.iJuhi">
                <input pInputText type="number" [(ngModel)]="customer.iJumi">
              </ng-template>
              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="customer.iJuhf">
                <input pInputText type="number" [(ngModel)]="customer.iJumf">
              </ng-template>
              <ng-template pTemplate="output">
                {{customer.iJuhi}}:{{customer.iJumi}} a {{customer.iJuhf}}:{{customer.iJumf}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="customer.iVihi">
                <input pInputText type="number" [(ngModel)]="customer.iVimi">
              </ng-template>
              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="customer.iVihf">
                <input pInputText type="number" [(ngModel)]="customer.iVimf">
              </ng-template>
              <ng-template pTemplate="output">
                {{customer.iVihi}}:{{customer.iVimi}} a {{customer.iVihf}}:{{customer.iVimf}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="customer.iSahi">
                <input pInputText type="number" [(ngModel)]="customer.iSami">
              </ng-template>
              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="customer.iSahf">
                <input pInputText type="number" [(ngModel)]="customer.iSamf">
              </ng-template>
              <ng-template pTemplate="output">
                {{customer.iSahi}}:{{customer.iSami}} a {{customer.iSahf}}:{{customer.iSamf}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="customer.iDohi">
                <input pInputText type="number" [(ngModel)]="customer.iDomi">
              </ng-template>
              <ng-template pTemplate="input">
                <input pInputText type="number" [(ngModel)]="customer.iDohf">
                <input pInputText type="number" [(ngModel)]="customer.iDomf">
              </ng-template>
              <ng-template pTemplate="output">
                {{customer.iDohi}}:{{customer.iDomi}} a {{customer.iDohf}}:{{customer.iDomf}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td (click)="traerdatos(customer)" class="pointer">
            Actualizar
          </td>
          <td (click)="deleteSelected(customer)" class="pointer">
            Activar/Desactivar
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="7">No hay Usuarios para mostrar.</td>
        </tr>
      </ng-template>
    </p-table>

  </div>
  <br>
  <br>
  <br>
  <br>

</div> -->


<div class="content-wrapper">
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-auto">
          <h1 class="m-0 pl-4 subtitle">Módulo de Usuarios</h1>
        </div>
        <!-- /.col -->
        <div class="col-sm-6 mt-1 border-left border-right">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item"><a href="#">Admin Usuarios</a></li>
            <li class="breadcrumb-item active">Encargado de Oficina</li>
          </ol>
        </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <!-- Main content -->
  <section class="content">
    <div class="container-fluid">

      <!-- Main row -->
      <div class="row">
        <!-- Left col -->
        <section class="col-lg-12 pl-3 pr-3 connectedSortable">

          <div class="card  box">
            <div class="row p-3">
              <div class="col-12">
                <h5>Asignar Encargados</h5>
                <button type="button" (click)="Seleccionar()" class="btn btn-block btn-success"><i class="fas fa-cogs"></i> Agregar
                  Nuevos Encargados</button>
              </div>
              <div class="col-12"> 
                <!-- table table-responsive table-hover mt-2 -->
                <div *ngIf="lockersV2" style="height: 700px; position: relative; right: 20px;">

                  <p-table #dt1 [value]="lockersV2" dataKey="id" [rows]="18" [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[10,25,50]" [loading]="loading" styleClass="p-datatable-customers p-datatable-gridlines"
                    [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [globalFilterFields]=
                    "['idL','idO','nPuertas','hab', 'sNomLocker','sDireccion','idComuna','sLatitud','sLongitud',
                    'iLuhi','iLumi','iLuhf','iLumf',
                    'iMahi','iMami','iMahf','iMamf',
                    'iMihi','iMimi','iMihf','iMimf',
                    'iJuhi','iJumi','iJuhf','iJumf',
                    'iVihi','iVimi','iVihf','iVimf',
                    'iSahi','iSami','iSahf','iSamf',
                    'iDohi','iDomi','iDohf','iDomf']">
                    <ng-template pTemplate="caption">
                      <div class="p-d-flex">
                        Lista de Encargados de oficina
                        <span class="p-input-icon-left p-ml-auto">
                          <i class="pi pi-search"></i>
                          <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                            placeholder="Buscar en Tabla" />
                        </span>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                      <tr>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            ID Locker
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Numero Puertas
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Estado
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Nombre de Locker
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Dirección
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Id Comuna
                          </div>
                        </th>
                        <!-- <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Latitud
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Longitud
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Lunes
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Martes
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Miercoles
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Jueves
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Viernes
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Sábado
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Domingo
                          </div>
                        </th> -->
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                            Act
                          </div>
                        </th>
                        <th>
                          <div class="p-d-flex p-jc-between p-ai-center">
                           Act/DesAct
                          </div>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-customer>
                      <tr>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="text" [(ngModel)]="customer.idLocker">
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{customer.idLocker}}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="text" [(ngModel)]="customer.iNumPuertas">
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{customer.iNumPuertas}}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="text" [(ngModel)]="customer.bHab">
                            </ng-template>
                            <ng-template pTemplate="output">
                              <div *ngIf="customer.bHab">Hab</div>
                              <div *ngIf="!customer.bHab">Deshab</div>
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="text" [(ngModel)]="customer.sNomLocker">
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{customer.sNomLocker}}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="text" [(ngModel)]="customer.sDireccion">
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{customer.sDireccion}}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="text" [(ngModel)]="customer.idComuna">
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{customer.idComuna}}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <!-- <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="text" [(ngModel)]="customer.sLatitud">
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{customer.sLatitud}}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="text" [(ngModel)]="customer.sLongitud">
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{customer.sLongitud}}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="number" [(ngModel)]="customer.iLuhi">
                              <input pInputText type="number" [(ngModel)]="customer.iLumi">
                            </ng-template>
                            <ng-template pTemplate="input">
                              <input pInputText type="number" [(ngModel)]="customer.iLuhf">
                              <input pInputText type="number" [(ngModel)]="customer.iLumf">
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{customer.iLuhi}}:{{customer.iLumi}} a {{customer.iLuhf}}:{{customer.iLumf}}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="number" [(ngModel)]="customer.iMahi">
                              <input pInputText type="number" [(ngModel)]="customer.iMami">
                            </ng-template>
                            <ng-template pTemplate="input">
                              <input pInputText type="number" [(ngModel)]="customer.iMahf">
                              <input pInputText type="number" [(ngModel)]="customer.iMamf">
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{customer.iMahi}}:{{customer.iMami}} a {{customer.iMahf}}:{{customer.iMamf}}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="number" [(ngModel)]="customer.iMihi">
                              <input pInputText type="number" [(ngModel)]="customer.iMimi">
                            </ng-template>
                            <ng-template pTemplate="input">
                              <input pInputText type="number" [(ngModel)]="customer.iMihf">
                              <input pInputText type="number" [(ngModel)]="customer.iMimf">
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{customer.iMihi}}:{{customer.iMimi}} a {{customer.iMihf}}:{{customer.iMimf}}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="number" [(ngModel)]="customer.iJuhi">
                              <input pInputText type="number" [(ngModel)]="customer.iJumi">
                            </ng-template>
                            <ng-template pTemplate="input">
                              <input pInputText type="number" [(ngModel)]="customer.iJuhf">
                              <input pInputText type="number" [(ngModel)]="customer.iJumf">
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{customer.iJuhi}}:{{customer.iJumi}} a {{customer.iJuhf}}:{{customer.iJumf}}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="number" [(ngModel)]="customer.iVihi">
                              <input pInputText type="number" [(ngModel)]="customer.iVimi">
                            </ng-template>
                            <ng-template pTemplate="input">
                              <input pInputText type="number" [(ngModel)]="customer.iVihf">
                              <input pInputText type="number" [(ngModel)]="customer.iVimf">
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{customer.iVihi}}:{{customer.iVimi}} a {{customer.iVihf}}:{{customer.iVimf}}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="number" [(ngModel)]="customer.iSahi">
                              <input pInputText type="number" [(ngModel)]="customer.iSami">
                            </ng-template>
                            <ng-template pTemplate="input">
                              <input pInputText type="number" [(ngModel)]="customer.iSahf">
                              <input pInputText type="number" [(ngModel)]="customer.iSamf">
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{customer.iSahi}}:{{customer.iSami}} a {{customer.iSahf}}:{{customer.iSamf}}
                            </ng-template>
                          </p-cellEditor>
                        </td>
                        <td pEditableColumn>
                          <p-cellEditor>
                            <ng-template pTemplate="input">
                              <input pInputText type="number" [(ngModel)]="customer.iDohi">
                              <input pInputText type="number" [(ngModel)]="customer.iDomi">
                            </ng-template>
                            <ng-template pTemplate="input">
                              <input pInputText type="number" [(ngModel)]="customer.iDohf">
                              <input pInputText type="number" [(ngModel)]="customer.iDomf">
                            </ng-template>
                            <ng-template pTemplate="output">
                              {{customer.iDohi}}:{{customer.iDomi}} a {{customer.iDohf}}:{{customer.iDomf}}
                            </ng-template>
                          </p-cellEditor>
                        </td> -->
                        <td (click)="traerdatos(customer)" class="pointer">
                          <i class="fas fa-edit"></i>
                        </td>
                        <td (click)="deleteSelected(customer)" class="pointer">
                          <i class="fas fa-user-alt-slash"></i>
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="7">No hay Usuarios para mostrar.</td>
                      </tr>
                    </ng-template>
                  </p-table>
              
                </div>
              </div>
            </div>

          </div>

        </section>
        <!-- /.Left col -->
        <!-- right col (We are only adding the ID to make the widgets sortable)-->

        <!-- right col -->
      </div>
      <!-- /.row (main row) -->
    </div>
    <!-- /.container-fluid -->
  </section>
  <!-- /.content -->
</div>
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalTecladoService } from 'src/app/core/services/modals/modal-teclado.service';
import { EmitTecladoService } from 'src/app/core/services/emit/emit-teclado.service';
import { ValidatorsService } from 'src/app/core/services/miscellaneous/validators.service';
import { KeyboardComponent } from 'src/app/custom/keyboard/keyboard.component';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ApiLockerService } from 'src/app/core/services/http/api-locker.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { FormControl } from '@angular/forms';
import { CanActivate, Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @Output() changeStep = new EventEmitter<any>();
  subscription: Subscription = new Subscription();
  user = new FormControl('');
  pass = new FormControl('');
  rutValid: any;
  disabled = false;
  usuarios = null;

  constructor(
    private modalTecladoService: ModalTecladoService,
    private emitTecladoService: EmitTecladoService,
    private validatorsService: ValidatorsService,
    private authService: AuthService,
    private ApiLockerService:ApiLockerService,
    private SweetAlertService:SweetAlertService,
    private Router:Router
  ) { }

  ngOnInit(): void {
    this.keyboardSubscription();
    this.traerusuarios();
    
  }

  async traerusuarios(){
    this.usuarios = await this.ApiLockerService.TraerUsuarios();
    //console.log(this.usuarios);
    
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  keyboardSubscription(): void {
    this.subscription.add(this.emitTecladoService.dataInput.subscribe(data => {
      if (data.input === 'inputUser') {
        this.rutValid = this.validatorsService.validarRut(data.texto.value);
        this.user.setValue(this.validatorsService.formaterRut(data.texto.value));
      } else {
        this.pass.setValue(data.texto);
      }

      if (this.rutValid && this.pass.value.length > 3) {
       this.disabled = true;
      }

    }));

  }

  desplegarRut(model: string): void {
    const options = { class: 'mt-5 p-5', initialState: { } };

    this.modalTecladoService.openModal(KeyboardComponent, options);

    this.emitTecladoService.enviarModel({
      input: model,
      teclado: 'rut',
      texto: this.user.value,
      inputType: 'text',
      max: 9,
    });
  }

  desplegarAlphaNumeric(model: string): void {
    const options = { class: 'mt-5', initialState: { } };

    this.modalTecladoService.openModal(KeyboardComponent, options);

    this.emitTecladoService.enviarModel({
      input: model,
      teclado: 'alphaNumerico',
      texto: this.pass.value,
      inputType: 'text',
      max: 15, // validar largo contraseña
    });
  }

  async onSubmit(user: string, pass: string): Promise<any> {
    //console.log('user:', user)
    //console.log('pass:', pass)
    //console.log(this.usuarios);
    var esto = this;
    var encontrado = false;
    this.usuarios.filter(function (array) {
      var rutusado = esto.validatorsService.formaterRut(array.rutMantisa + "-" +array.rutDV)
      if (rutusado == user && pass["value"] == array.clave) {
        sessionStorage.setItem('triageUser: ', user);
        esto.changeStep.emit('mantenedor');
        encontrado = true;
      }
    });
    if (encontrado == false) {
      esto.SweetAlertService.swalErrorgeneral("Datos invalidos","Alguno de los datos ingresados no coinciden, !Por favor intente nuevamente¡");
    }
    // const response = await this.authService.ValidaLogin(user, pass);
    // if (response['status']) {
    //   sessionStorage.setItem('triageUser: ', user);
    //   this.changeStep.emit('mantenedor');
    // }
  }
  
  volver() {
    this.Router.navigate(['']);
  }

}

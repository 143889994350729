import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modals/modal.service';

import { PruebasService } from '../../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RutService } from 'src/app/core/services/http/rut.service';
import { Estado, Oficinas } from '../../../customer';
import { OFICINA, ROL } from "../../constant/oficinas.constant";

@Component({
  selector: 'app-admi-usu-modal',
  templateUrl: './admi-usu-modal.component.html',
  styleUrls: ['./admi-usu-modal.component.scss']
})
export class AdmiUsuModalComponent implements OnInit {

  registerForm: FormGroup;
  submitted: boolean = false;
  rutValid: boolean = false;
  // Asignar respuesta para el front
  roles: any[] = []
  oficinas: any[] = []
  ofi;
  title
  onClose: Subject<boolean> = new Subject();
  Estado = [
    {
      estado: 'Habilitado',
      status: true
    },
    {
      estado: 'Desahabilitado',
      status: false
    }
  ]
  restUsuario: any = {
    dniUsu: '',
    nomUsu: '',
    sPassusu: '',
    bHab: '',
    idRol: '',
    idOficina: '',
    nombreRol: '',
    nombreOficina: '',
  }
  datosInterface;
  //@Input() usuOfi;
  @Input() public usuario: any;

  constructor(private modalService: ModalService,
    private formBuilder: FormBuilder,
    private PruebasService: PruebasService,
    private rutService: RutService,
    private SweetAlertService: SweetAlertService,
    private router: Router) {
    this.iniciarDato();

  }

  async ngOnInit(): Promise<void> {

    this.SweetAlertService.swalLoading();
    await this.obtenerRol();
    await this.obtenerOficina();
    await this.setValue();
    this.SweetAlertService.swalClose();

  }

  get f() { return this.registerForm.controls; }

  cerrarModal() {
    // this.onClose.next(true);
    this.modalService.closeModal();
  }
  // Inicia el formulario 
  async iniciarDato() {
    this.registerForm = this.formBuilder.group({
      dniUsu: ['', [Validators.required, Validators.minLength(8)]],
      nomUsu: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^[A-Za-z0-9 ]+$/)]],
      sPassusu: this.usuario === undefined ? [] : ['', [Validators.required, Validators.minLength(4)]],
      bHab: ['', [Validators.required]],
      idRol: ['', [Validators.required]],
      idOficina: ['', [Validators.required]]
    })
  }



  /**Inserta cliente en BD
   * desestructura en variables los valores del formulario, asignan a un objeto
   * si del objeto asigando uno de los campos tiene un largo igual a 0 
   * debe terminar la acción.
   * caso contrario llamada al servicio de crear usuario, entregando el valor del
   * objeto restUsuario, valor es asinado a una variable como respuesta 
   * para saber el status y de esta manera indicar si fue un exito o no la operacion.
   */
  async insertCliente() {
    let hab;
    const { dniUsu, nomUsu, sPassusu, bHab, idRol, idOficina } = this.registerForm.value;
    bHab === 'Habilitado' ? hab = true : hab = false

    this.restUsuario = {
      dniUsu,
      nomUsu,
      sPassusu,
      bHab: hab,
      idRol: this.GetRolSelect(idRol),
      idOficina: this.GetOficinaSelect(idOficina)
    }
    console.log(idRol);

    if (this.restUsuario.dniUsu.trim().length === 0 || null) {
      this.cerrarModal();
      return;
    }
    let datosRetornados = await this.PruebasService.crearUsuario(this.restUsuario)
    if (datosRetornados.status == true) {
      this.SweetAlertService.swalSuccess("Éxito", "¡Usuario creado!");
      this.cerrarModal();
    }
    else {
      this.SweetAlertService.swalError("¡No se puedo crear el usuario, intente nuevamente!");
    }
    setTimeout(() => {
       this.refresh();
    }, 2000);

  }

  refresh(): void {
    window.location.reload();
  }
  async obtenerOficina() {
    this.oficinas = await this.PruebasService.TraerOficinas()
  }

  async obtenerRol() {
    this.roles = await this.PruebasService.TraerRoles()
  }

  setRut(): void {
    this.rutValid = this.rutService.validarRut(
      this.registerForm.value.dniUsu.replace(/\./g, '').replace('-', '')
    );
    this.registerForm.patchValue({
      dniUsu: this.rutService.formateaRut(this.registerForm.value.dniUsu),
    });

    if (this.rutValid) {
      // Validando que el rut ingresado contenga sólo números y la letra K
      const rutRegex = /^\d{1,3}(?:\.\d{1,3}){2}-[\dkK]$/;
      this.rutValid = rutRegex.test(this.registerForm.value.dniUsu);
    }
  }

  setValue() {
    let hab
    if (this.usuario === undefined) {
      return;
    }
    //const de usuario viene desde el elemento padre
    const { usuario } = this.usuario;
    const { dniUsu, nomUsu, sPassusu, bHab, idRol, idOficina, nombreRol, nombreOficina } = usuario
    this.restUsuario = { dniUsu, nomUsu, sPassusu, bHab, idRol, idOficina, nombreRol, nombreOficina }
    console.log(this.restUsuario);

    bHab == true ? hab = 'Habilitado' : hab = 'Desahabilitado'
    this.registerForm.patchValue({
      dniUsu: this.restUsuario.dniUsu,
      nomUsu: this.restUsuario.nomUsu,
      // sPassusu  :this.restUsuario.sPassusu
      bHab: hab,
      idRol: this.restUsuario.nombreRol,
      idOficina: this.restUsuario.nombreOficina
    })
    this.setRut();
  }

  async editarUsuario() {
    let hab;
    const { bHab, idRol, idOficina } = this.registerForm.value;
    console.log(bHab);

    bHab === 'Habilitado' ? hab = true : hab = false

    this.restUsuario = {
      dniUsu: this.registerForm.value.dniUsu,
      nomUsu: this.registerForm.value.nomUsu,
      sPassusu: this.registerForm.value.sPassusu,
      bHab: hab,
      idRol: this.GetRolSelect(idRol),
      idOficina: this.GetOficinaSelect(idOficina)
    }
    // console.log('195', this.restUsuario);
    if (this.restUsuario.dniUsu.trim().length === 0 || null) {
      this.cerrarModal();
      return;
    }
    let datosRetornados = await this.PruebasService.actualizarUsuarios(this.restUsuario)
    if (datosRetornados.status == true) {
      this.SweetAlertService.swalSuccess("Éxito", "¡Usuario actualizado!");
      this.cerrarModal();
    }
    else {
      this.SweetAlertService.swalError("¡No se puede actualizar el usuario, intente nuevamente!");
    }
    setTimeout(() => {
      this.refresh();
    }, 2000);
  }

  onSubmit(cerrar) {
    if (cerrar == true) {
      this.cerrarModal();
    }
    else {
      console.log('Enviando');
      if (this.usuario === undefined) {
        this.insertCliente();
        this.onReset()
        return;
      } else {
        this.editarUsuario();
        this.onReset();
        return;
      }
    }


  }

  onReset() {
    setTimeout(() => {
      this.registerForm.reset();
    }, 1000);
  }
  // Metodos de validacion en html
  formDirtyRequire(dato: any) {
    return this.registerForm.get(dato).errors?.dirty && this.registerForm.get(dato).errors?.required
  }
  formDirty(dato: any) {
    return this.registerForm.get(dato).errors?.dirty
  }
  formTouchLenght(dato: any) {
    return this.registerForm.get(dato).touched && this.registerForm.get(dato).errors?.minLength
  }
  formTouched(dato: any) {
    return this.registerForm.get(dato)?.touched
  }
  formErrors(dato: any) {
    return this.registerForm.get(dato).errors
  }
  campoRequerido(dato: any) {
    return this.registerForm.get(dato)?.errors?.required
  }
  minLargo(dato: any) {
    return this.registerForm.get(dato)?.errors?.minlength
  }
  minLargoRequerido(dato: any) {
    return this.registerForm.get(dato)?.errors?.minlength.requiredLength
  }
  maxLargo(dato: any) {
    return this.registerForm.get(dato)?.errors?.maxlength
  }
  maxLargoRequerido(dato: any) {
    return this.registerForm.get(dato)?.errors?.maxlength.requiredLength
  }
  caracterEspecial(dato: any) {
    return this.registerForm.get(dato)?.errors?.pattern
  }
  validaRut(): void {
    this.rutValid = this.rutService.validarRut(this.registerForm.value.rut.replace(/\./g, '').replace('-', ''));
    this.registerForm.patchValue({ rut: this.rutService.formateaRut(this.registerForm.value.registerForm) })
  }

  GetRolSelect(rol) {
    for (let index = 0; index < this.roles["data"].length; index++) {
      const element = this.roles["data"][index];
      if (element["descripcion"]== rol) {
        console.log("encontrol rol", element);
        
        return element["idRol"]
      }
    }
  }

  GetOficinaSelect(ofi) {
    for (let index = 0; index < this.oficinas["data"].length; index++) {
      const element = this.oficinas["data"][index];
      if (element["sOficina"]== ofi) {
        console.log("encontrol oficina", element);
        
        return element["idOficina"]
      }
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modals/modal.service';
import { Clientes, Nombre } from '../../../customer';
import { PruebasService } from '../../../core/services/http/pruebas.service';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subject } from 'rxjs';
import { RutService } from '../../../core/services/http/rut.service';
import { TipoElemento } from '../../../customer';
import { UsuariosAdm } from '../../interfaces/interface';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-clientes-modal',
  templateUrl: './clientes-modal.component.html',
  styleUrls: ['./clientes-modal.component.scss']
})
export class ClientesModalComponent implements OnInit {

  formulario: FormGroup;
  isSubmitted: boolean = false;
  rutValid: boolean = false;

  onClose: Subject<boolean> = new Subject();
  @Input() public usuario: any;
  ListaRoles: [] = [];
  ListaOficinas: [] = [];
  resClientes: UsuariosAdm = {
    rutCliente: '',
    nombre: '',
    correo: '',
    telefono: ''
  }
  title;

  constructor(
    private modalService: ModalService,
    private formBuilder: FormBuilder,
    private PruebasService: PruebasService,
    private RutService: RutService,
    private SweetAlertService: SweetAlertService,
    private rutService: RutService,
    private router: Router,
    public modalRef: BsModalRef) {
    this.iniciarDato();
  }

  async ngOnInit(): Promise<void> {
    this.SweetAlertService.swalLoading();

    this.setValue();
    this.SweetAlertService.swalClose();
  }

  get f() { return this.formulario.controls; }

  cerrarModal() {
    this.onClose.next(true);
    this.modalService.closeModal();
  }


  async iniciarDato() {
    this.formulario = this.formBuilder.group({
      rut     : ['', [Validators.required, Validators.minLength(8)]],
      nombre  : ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^[A-Za-z0-9 ]+$/)]],
      email   : ['', [Validators.required, Validators.pattern(
                /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i),],],
      telefono: ['', [Validators.required, Validators.min(100000000), Validators.max(999999999), Validators.pattern(/^[2-9](\s?)[9876543]\d{7}$/),],],
    });
  }

  async insertCliente() {
    const { rut, nombre, email, telefono } = this.formulario.value;
    this.resClientes = {
      rutCliente: rut,
      nombre: nombre,
      correo: email,
      telefono: telefono
    }
    if (this.resClientes.rutCliente.trim().length === 0 || null) {
      return;
    }

    let response = await this.PruebasService.agregarClienteAdmin(this.resClientes);
    console.log('100', response);
    if (response.status == true) {
      this.SweetAlertService
        .swalSuccess('Usuario', 'El usuario fue agregado exítosamente');
      this.cerrarModal();
    } else {
      this.SweetAlertService.swalError("¡No se puedo crear el usuario, intente nuevamente!");
    }
    setTimeout(() => {
      this.refresh();
    }, 2000);

  }

  setValue() {
    if (this.usuario === undefined) {
      return;
    }
    const { usuario } = this.usuario;
    const { rutCliente, nombre, correo, telefono } = usuario
    this.resClientes = { rutCliente, nombre, correo, telefono }

    this.formulario.setValue({
      rut: this.resClientes.rutCliente,
      nombre: this.resClientes.nombre,
      email: this.resClientes.correo,
      telefono: this.resClientes.telefono
    })
    // console.log( 'El form',this.formulario.value);
  }

  async editarCliente() {
    this.resClientes = {
      rutCliente: this.formulario.value.rut,
      nombre: this.formulario.value.nombre,
      correo: this.formulario.value.email,
      telefono: this.formulario.value.telefono
    }

    if (this.resClientes.rutCliente.trim().length === 0) {
      return;
    }
    let datosRetornados = await this.PruebasService.actulizarClienteAdmin(this.resClientes)
    console.log(datosRetornados);
    if (datosRetornados.status == true) {
      this.SweetAlertService.swalSuccess("Éxito","¡Usuario actualizado!");    
      this.cerrarModal();
    }else {
      this.SweetAlertService.swalError("¡No se puede actualizar el usuario, intente nuevamente!");
    }
    
    setTimeout(() => {
      this.refresh();
    }, 2000);
  }

  refresh(): void {
    window.location.reload();
  }

  setRut(): void {
    this.rutValid = this.rutService.validarRut(
      this.formulario.value.rut.replace(/\./g, '').replace('-', '')
    );
    this.formulario.patchValue({
      rut: this.rutService.formateaRut(this.formulario.value.rut),
    });

    if (this.rutValid) {
      // Validando que el rut ingresado contenga sólo números y la letra K
      const rutRegex = /^\d{1,3}(?:\.\d{1,3}){2}-[\dkK]$/;
      this.rutValid = rutRegex.test(this.formulario.value.rut);
    }
  }

  onSubmit() {
    // console.log('formulario', JSON.stringify(this.formulario.value));
    if (!this.usuario) {
      console.log('en el insert');
      this.insertCliente();

    } else {
      console.log('en el edit');
      this.editarCliente();
    }
  }


  onReset() {
    setTimeout(() => {
      this.formulario.reset();
    }, 1000);
  }
  // Metodos de validacion en html
  formDirtyRequire(dato: any) {
    return this.formulario.get(dato).errors?.dirty && this.formulario.get(dato).errors?.required
  }
  formDirty(dato: any) {
    return this.formulario.get(dato).errors?.dirty
  }
  formTouchLenght(dato: any) {
    return this.formulario.get(dato).touched && this.formulario.get(dato).errors?.minLength
  }
  formTouched(dato: any) {
    return this.formulario.get(dato)?.touched
  }
  formErrors(dato: any) {
    return this.formulario.get(dato).errors
  }
  campoRequerido(dato: any) {
    return this.formulario.get(dato)?.errors?.required
  }
  minLargo(dato: any) {
    return this.formulario.get(dato)?.errors?.minlength
  }
  minLargoRequerido(dato: any) {
    return this.formulario.get(dato)?.errors?.minlength.requiredLength
  }
  maxLargo(dato: any) {
    return this.formulario.get(dato)?.errors?.maxlength
  }
  maxLargoRequerido(dato: any) {
    return this.formulario.get(dato)?.errors?.maxlength.requiredLength
  }
  caracterEspecial(dato: any) {
    return this.formulario.get(dato)?.errors?.pattern
  }
  validaRut(): void {
    this.rutValid = this.RutService.validarRut(this.formulario.value.rut.replace(/\./g, '').replace('-', ''));
    this.formulario.patchValue({ rut: this.RutService.formateaRut(this.formulario.value.rut) });
  }

  // formulario: FormGroup = this.formBuilder.group({
  //   rut: ['', [Validators.required, Validators.minLength(8)]],
  //   nombre: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(100), Validators.pattern(/^[A-Za-z0-9 ]+$/)]],
  //   email: ['', [Validators.required, Validators.pattern(
  //     /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i),],],
  //   telefono: ['', [Validators.required, Validators.min(100000000), Validators.max(999999999), Validators.pattern(/^[2-9](\s?)[9876543]\d{7}$/),],],
  // });

  // async obtenerRoles() {
  //   var tipoRol = await this.PruebasService.TraerRoles();
  //   if (tipoRol["status"] == true) {
  //     this.ListaRoles = tipoRol["data"];
  //   }
  //   else{
  //     this.SweetAlertService.swalClose();
  //     this.SweetAlertService.swalError("no se pueden crear elementos en este momento, vuelva a intentar");
  //   }
  // }

  // async obtenerOficinas()
  // {
  //   var tipoOficina = await this.PruebasService.TraerOficinas();
  //   if (tipoOficina["status"] == true) {
  //     this.ListaOficinas = tipoOficina["data"];
  //     console.log("USUARIOS PARA OFICINA: ", this.ListaOficinas);

  //   }
  //   else{
  //     this.SweetAlertService.swalClose();
  //     this.SweetAlertService.swalError("no se pueden crear elementos en este momento, vuelva a intentar");
  //   }
  // }
  // obtenerdatos() {
  //   console.log(this.registerForm.value);
  //   this.datosInterface = {
  //     "dniUsu": this.registerForm.value.sRut.replaceAll('.',''),
  //     "nomUsu": this.registerForm.value.sNom,
  //     "sPassusu": this.registerForm.value.sClave,
  //     "bHab": this.registerForm.value.eUsuario,
  //     "idRol": this.registerForm.value.sIdRol,
  //     "idOficina": this.registerForm.value.idOfi
  //   }
  //   console.log('Datos Interfaz: ', this.datosInterface);
  // }

  // async agregarCliente() {

  //   this.submitted = true;
  //   if(this.registerForm.invalid){return;}

  //   this.SweetAlertService.swalLoading();
  //   await this.obtenerdatos();
  //   console.log(this.datosInterface);
  //   let datosRetornados = await this.PruebasService.AgregarClientesMethanext(this.datosInterface)
  //   console.log('RESPONSE: ', datosRetornados);
  //   this.SweetAlertService.swalClose();
  //   if (datosRetornados.status == true) {
  //     await this.SweetAlertService.swalSuccess("Éxito","¡Cliente Creado!");
  //     this.cerrarModal();
  //   }
  //   else {
  //     this.SweetAlertService.swalError("¡No se pudo crear al cliente, intente nuevamente!");
  //   }
  // }

}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LockerComponent } from './locker/locker/locker.component';
import { SafeComponent } from './safe/safe.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LockerComponent,
    SafeComponent
  ],
  imports: [
    FormsModule,
    CommonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LockerComponent,
    SafeComponent
  ]
})
export class SharedModule { }

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TarjetaLockerService } from '../../../../core/services/http/tarjeta-locker.service';
import { SweetAlertService } from '../../../../core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-mantenedor',
  templateUrl: './mantenedor.component.html',
  styleUrls: ['./mantenedor.component.scss']
})
export class MantenedorComponent implements OnInit {
  @Output() changeStep = new EventEmitter<any>();

  constructor(private Router: Router, private TarjetaLockerService: TarjetaLockerService, private SweetAlertService: SweetAlertService) { }

  ngOnInit(): void {
  }

  volver() {
    this.Router.navigate(['']);
  }

  async Abrirtodos() {
    this.SweetAlertService.CargandoDatos();
    var resp = await this.TarjetaLockerService.AbrirTodasPuerta();
    this.SweetAlertService.swalClose();
  }

  async Apagar() {
    this.SweetAlertService.CargandoDatos();
    var resp = await this.TarjetaLockerService.Apagar();
    this.SweetAlertService.swalClose();
  }

  async Reiniciar() {
    this.SweetAlertService.CargandoDatos();
    var resp = await this.TarjetaLockerService.Reiniciar();
    this.SweetAlertService.swalClose();
  }

}

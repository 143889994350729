import { Component, OnInit, ViewChild } from '@angular/core';
import { PruebasService } from '../../../core/services/http/pruebas.service';
import { Elemento, TipoElemento } from '../../../customer';
import { ModalService } from '../../../core/services/modals/modal.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from '../../../core/services/sweet-alert/sweet-alert.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ElementoModalComponent } from './elemento-modal/elemento-modal.component';
//exportar a excel
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
//exportar a pdf
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

@Component({
  selector: 'app-elemento',
  templateUrl: './elemento.component.html',
  styleUrls: ['./elemento.component.scss'],
})
export class ElementoComponent implements OnInit {
  Elementos: Elemento[];
  ElementV2: Elemento[] = [];
  dataTable: Elemento[] = [];
  myGroup: FormGroup;
  datosInterface: Elemento;
  selectedProducts: Elemento[];
  modalRef: BsModalRef;
  TipoElementos: TipoElemento[] = [];
  // exportación
  columns: any[] = [];
  exportColumns: any[];
  cols: any[];
  fileName = 'Elemento.xlsx';
  descripciones: any;
  elementosID: any;

  loading;
  filteredValues: any;

  sesionrol

  constructor(
    private PruebasService: PruebasService,
    private modalService: ModalService,
    private Router: Router,
    private SweetAlertService: SweetAlertService
  ) {}

  async ngOnInit() {
    this.sesionrol = localStorage.getItem('rolsesion')
    this.iniciarDatos();
    this.loading = true;
    this.llenarTabla();
    this.loading = false;
  }
  volver() {
    this.Router.navigate(['MenuOpciones']);
  }

  async iniciarDatos() {
    this.myGroup = new FormGroup({
      IdtipoElemento: new FormControl(),
      nombre: new FormControl(),
    });
  }

  async EditarDatos(datos: Elemento) {
    console.log(datos);
    var reqCliente = {
      id: datos.idElemento,
      idTipo: datos.idTipo,
      serial: datos.serial,
      usos: datos.usos,
      estado: datos.estado,
    };
    // this.exportColumns = this.cols.map((col) => ({
    //   title: col.header,
    //   dataKey: col.field,
    // }));
    this.SweetAlertService.swalLoading();
    var resp = await this.PruebasService.EditarElemntos(reqCliente);
    if (resp['status']) {
      await this.llenarTabla();
      this.SweetAlertService.swalSuccess(
        'Éxito',
        'Se ha modificado tipo de elemento'
      );
    } else {
      this.SweetAlertService.swalError(
        'Error, No se pudieron realizar los cambios. Intente nuevamente'
      );
    }
  }
  async EliminarDatos(datos: Elemento) {
    var confirma = await this.SweetAlertService.swalConfirm(
      '¿Desea Eliminar este tipo de elemento?',
      'Solamente se podra elimniar el tipo de elemento si, no posee un elemnto asociado'
    );
    if (confirma == true) {
      console.log(datos);
      var reqCliente = datos.idElemento;
      this.SweetAlertService.swalLoading();
      var resp = await this.PruebasService.EliminarTipoElemntos(reqCliente);
      if (resp['status']) {
        await this.llenarTabla();
        this.SweetAlertService.swalSuccess(
          'Éxito',
          'Se ha eliminado tipo de elemento'
        );
      } else {
        this.SweetAlertService.swalError(
          'Error, recuerde que no puede tener ningun elmento asociado'
        );
      }
    }
  }

  async llenarTabla() {
    this.SweetAlertService.swalLoading();
    let tiposelem = await this.PruebasService.TraerTipoElemento();
    let response = await this.PruebasService.ListarElemntos();
    if (tiposelem['status'] && response['status']) {
      this.TipoElementos = tiposelem['data'];
      this.ElementV2 = response['data']['elementos'];
      this.descripciones = this.TipoElementos.map(
        (value: any) => value.descripcion
      );
      this.elementosID = this.ElementV2.map((value: any) => value.idElemento);
      this.dataTable = this.ElementV2.map((value: any) => ({
        ...value,
        descripcion: this.TipoElementos.find(
          (x: any) => value.idTipo == x.idTiposElemento
        ).descripcion,
      }));
      this.filteredValues = this.dataTable;
    } else {
      this.SweetAlertService.swalError(
        'no se puede crear elementos en este momento, vuelva a intentar'
      );
    }
    this.SweetAlertService.swalClose();
  }

  Seleccionar() {
    const opcion = {
      class: 'w-50',
      initialState: { regiones: this.ElementV2 },
    };
    this.modalRef = this.modalService.openModal(ElementoModalComponent, opcion);
    this.modalRef.content.onClose.subscribe((result) => {
      console.log('result: ', result);
      if (result) {
        this.llenarTabla();
      }
    });
  }

  onFilter(event, dt) {
    this.filteredValues = event.filteredValue;
  }

  //EXPORTAR A EXCEL (SE FILTRAN LOS DATOS A IMPRIMIR)
  exportExcel() {
    var datos = [];
    var toExport = [];
    if (this.filteredValues) {
      datos = this.filteredValues;
      for (let element of datos) {
        var obj = {
          ID: element.idElemento,
          Tipo: element.idTipo,
          Serial: element.serial,
          Uso: element.usos,
          Estado: element.estado,
        };
        toExport.push(obj);
      }
      import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(toExport);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        this.saveAsExcelFile(excelBuffer, 'Elementos');
      });
    } else {
      datos = this.ElementV2;
      for (let element of datos) {
        var obj = {
          ID: element.idElemento,
          Tipo: element.idTipo,
          Serial: element.serial,
          Uso: element.usos,
          Estado: element.estado,
        };
        toExport.push(obj);
      }
      import('xlsx').then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(toExport);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, {
          bookType: 'xlsx',
          type: 'array',
        });
        this.saveAsExcelFile(excelBuffer, 'Elementos');
      });
    }
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver) => {
      let EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    });
  }

  //EXPORTAR A PDF
  exportPdf() {
    var datos = [];
    var toExport = [];
    datos = this.filteredValues;
    for (let element of datos) {
      var obj = {
        ID: element.idElemento,
        Tipo: element.idTipo,
        Serial: element.serial,
        Uso: element.usos,
        Estado: element.estado,
      };
      toExport.push(obj);
    }
    var doc = new jsPDF('p', 'pt');
    doc.text('Elementos', 50, 30);
    autoTable(doc, { columns: this.exportColumns, body: toExport });
    doc.save('Elementos.pdf');
  }

  async EliminarElemento(datos: Elemento) {
    var confirma = await this.SweetAlertService.swalEliminar(
      '¿Desea Eliminar el Elemento?',
      'No se podrá revertir ésta acción'
    );
    if (confirma == true) {
      console.log(datos);
      var reqElementos = datos.idElemento;
      this.SweetAlertService.swalLoading();
      var resp = await this.PruebasService.EliminarElementos(reqElementos);
      if (resp['status']) {
        await this.llenarTabla();
        await this.SweetAlertService.swalUsuarioEliminado(
          'Éxito',
          'Se ha eliminado correctamente el elemento'
        );
      } else {
        this.SweetAlertService.swalError('Error, intente nuevamente...');
      }
    }
  }
}

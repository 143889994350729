import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class ReporteriaService {
  headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: 'Auth dHRwOjIwMjEu'
  });

  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient
  ) { }

  async TransaccionListFechas(data: any): Promise<any> {
    try {
      var headers = this.headers.set('Authorization', 'Bearer ' +localStorage.getItem('tokenUser'));
      const API_URL = await this.configService.getConfig();
      const url = `${API_URL['DatosMethanex']}/Pagos/TransaccionListFechas`;

      return await this.httpClient.post(url, data, { headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async ListarEmpresa(): Promise<any> {
    try {
      var headers = this.headers.set('Authorization', 'Bearer ' +localStorage.getItem('tokenUser'));
      const API_URL = await this.configService.getConfig();
      const url = `${API_URL['DatosMethanex']}/Listar/ListarEmpresa`;

      return await this.httpClient.get(url, { headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async ListarOficina(): Promise<any> {
    try {
      var headers = this.headers.set('Authorization', 'Bearer ' +localStorage.getItem('tokenUser'));
      const API_URL = await this.configService.getConfig();
      const url = `${API_URL['DatosMethanex']}/Oficinas/GetOficinas`;

      return await this.httpClient.get(url, { headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async ListarUsuarios(): Promise<any> {
    try {
      var headers = this.headers.set('Authorization', 'Bearer ' +localStorage.getItem('tokenUser'));
      const API_URL = await this.configService.getConfig();
      const url = `${API_URL['DatosMethanex']}/Listar/ListarUsuario`;

      return await this.httpClient.get(url, { headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async ListarTotem(idOffice: number): Promise<any> {
    try {
      var headers = this.headers.set('Authorization', 'Bearer ' +localStorage.getItem('tokenUser'));
      const API_URL = await this.configService.getConfig();
      const params = `?idOficina=${idOffice}`;
      const url = `${API_URL['DatosMethanex']}/Listar/ListarTotem${params}`;

      return await this.httpClient.get(url, { headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async ListarUsuarioDetallado(idUser: number): Promise<any> {
    try {
      var headers = this.headers.set('Authorization', 'Bearer ' +localStorage.getItem('tokenUser'));
      const API_URL = await this.configService.getConfig();
      const params = `?idUsuario=${idUser}`;
      const url = `${API_URL['endpoint']}/Listar/ListarUsuarioDetallado${params}`;

      return await this.httpClient.get(url, { headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async ListarUsabilidad(data: any): Promise<any> {
    try {
      var headers = this.headers.set('Authorization', 'Bearer ' +localStorage.getItem('tokenUser'));
      const API_URL = await this.configService.getConfig();
      const url = `${API_URL['endpoint']}/Usabilidad/ListarUsabilidad`;

      return await this.httpClient.post(url, data, { headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async ReporteriaLOG(data: any): Promise<any> {
    try {
      var headers = this.headers.set('Authorization', 'Bearer ' +localStorage.getItem('tokenUser'));
      const API_URL = await this.configService.getConfig();
      const url = `${API_URL['BaseAPI']}Reporte/ReporteUsabilidad`;

      return await this.httpClient.post(url, data, { headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async ReporteSesion(data: any): Promise<any> {
    try {
      var headers = this.headers.set('Authorization', 'Bearer ' +localStorage.getItem('tokenUser'));
      const API_URL = await this.configService.getConfig();
      const url = `${API_URL['BaseAPI']}ReporteSesion/FiltrarSesion`;

      return await this.httpClient.post(url, data, { headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }

  async ReporteCargaDesc(data: any): Promise<any> {
    try {
      var headers = this.headers.set('Authorization', 'Bearer ' +localStorage.getItem('tokenUser'));
      const API_URL = await this.configService.getConfig();
      const url = `${API_URL['BaseAPI']}Reporte/ReporteUso`;

      return await this.httpClient.post(url, data, { headers }).toPromise();
    } catch (error) {
      return { status: false, code: 804, message: 'Error al ejecutar la petición.' };
    }
  }
}

<!-- main app container -->
<div class="container-fluid overflow-auto" style="height: 250px !important; margin-top: 10%;">
    <h5 class="card-header mt-4" class="text-secondary">Crear Tipo de Elmento</h5>
    <div class="card-body">
        <form [formGroup]="registerForm" class="text-secondary" *ngIf="registerForm">
            <div class="form-group">
                <label for="exampleInputZone">Nombre de Elemento</label>
                <input type="text" [ngClass]="{'is-invalid': submitted && registerForm.get('descripcion').errors}" formControlName="descripcion" class="form-control" >
                <div *ngIf="submitted && registerForm.get('descripcion').errors">
                <small class="msg-error">Ingrese un nombre</small>
                </div>
            </div>
        </form>  
    </div>
    <form>
        <div class="text-center">
            <button class="btn btn-primary mr-1" type="submit" (click)="agregarCliente()">GUARDAR</button>
            <button class="btn btn-secondary" (click)="cerrarModal()">CANCELAR</button>
        </div>
    </form>
</div>
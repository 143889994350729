<div class="row" [formGroup]="formulario">

  <div class="form-group col-sm-2 mx-auto">
    <label>Fecha Inicio</label>
    <input type="date" class="form-control text-uppercase text-center" placeholder="DD/MM/AAAA" formControlName="fechaInicio" [max]="maxDate" [ngClass]="{ 'is-invalid': isSubmitted && f.fechaIn.invalid }" (change)="action()">
    <div class="invalid-feedback d-block text-center" *ngIf="isSubmitted && f.fechaIn.invalid">
      <i class="far fa-times-circle"></i> Por favor, selecciona una fecha inicio.
    </div>
  </div>

  <div class="form-group col-sm-2 mx-auto">
    <label>Fecha Fin</label>
    <input type="date" class="form-control text-uppercase text-center" placeholder="DD/MM/AAAA" formControlName="fechaFin" [max]="maxDate" [ngClass]="{ 'is-invalid': isSubmitted && f.fechaFin.invalid }" (change)="action()">
    <div class="invalid-feedback d-block text-center" *ngIf="isSubmitted && f.fechaFin.invalid">
      <i class="far fa-times-circle"></i> Por favor, selecciona una fecha fin.
    </div>
  </div>

  <!-- <div class="form-group col-sm-2 mx-auto">
    <label>Oficina</label>
    <select class="form-control" formControlName="idOficina" [ngClass]="{ 'is-invalid': isSubmitted && f.idOficina.invalid }" (change)="action()">
      <option value="" disabled selected>SELECCIONA</option>
      <option *ngFor="let oficina of oficinas" [ngValue]="oficina.idOficina">{{ oficina.nombreOficina }}</option>
    </select>
    <div *ngIf="isSubmitted && f.idOficina.invalid" class="invalid-feedback">
      <i class="far fa-times-circle"></i> Por favor, selecciona oficina.
    </div>
  </div>

  <div class="form-group col-sm-2 mx-auto">
    <label>Kiosko</label>
    <select class="form-control" formControlName="idTotem" [ngClass]="{ 'is-invalid': isSubmitted && f.idTotem.invalid }" (change)="action()">
      <option value="" disabled selected>SELECCIONA</option>
      <option [ngValue]="0" *ngIf="f.idOficina.valid">TODOS</option>
      <option *ngFor="let kiosko of kioskos" [ngValue]="kiosko.idtotem">{{ kiosko.nombreTotem }}</option>
    </select>
    <div *ngIf="isSubmitted && f.idTotem.invalid" class="invalid-feedback">
      <i class="far fa-times-circle"></i> Por favor, selecciona kiosko.
    </div>
  </div> -->

</div>
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SweetAlertService } from '../../core/services/sweet-alert/sweet-alert.service';
import { PruebasService } from '../../core/services/http/pruebas.service';
import { login } from 'src/app/customer';
import { RutService } from '../../core/services/http/rut.service';
import { DataProcessService } from './../../core/services/http/data-process.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';

@Component({
  selector: 'app-login-usuario',
  templateUrl: './login-usuario.component.html',
  styleUrls: ['./login-usuario.component.scss']
})
export class LoginUsuarioComponent implements OnInit {

  rutValid: boolean;
  login: login[] = [];
  registerForm: FormGroup;
  myGroup: FormGroup;
  email = new FormControl('');
  password = new FormControl('');
  usuarios = null;
  rut = '';
  nombreUsuario = '';
  passUsuario = '';
  estado = true;
  submited: boolean;

  
  
  constructor(
    private authService : AuthService,
    private router: Router,
    private dataProcessService: DataProcessService,
    private SweetAlertService: SweetAlertService,
    private PruebasService: PruebasService,
    private RutService:RutService,
    private Router: Router) { }

  ngOnInit(): void {
    localStorage.setItem('tokenUser', null);
    this.iniciarDatos()
  }

  volver() {
    this.Router.navigate(['MenuOpciones']);
  }

  salir()
  {
    this.Router.navigate(['']);
    this.dataProcessService.deleteDataProcess();
  }

  iniciarDatos()
  {
    this.myGroup = new FormGroup({
      usuarioRut: new FormControl(),
      usuarioPass: new FormControl()
     }); 
  }

  get f() { return this.registerForm.controls; }



  async ValidaLogin(){
    this.submited = true;
    if(!this.rutValid || !this.myGroup.valid)
    {
      return;
    }
    let datosInterface : login = {
      rut: this.myGroup.value.usuarioRut.replaceAll('.',''),
      password: this.myGroup.value.usuarioPass
    }
    this.SweetAlertService.swalLoading();
    
    // let datosRetornados = await this.PruebasService.loginUsuario(datosInterface)
    let datosRetornados = await this.authService.login(datosInterface)
    var estadoBtn = true;
    if(datosRetornados.status == true)
    {
          localStorage.setItem('tokenUser', datosRetornados["data"]["token"]["token"]);
          localStorage.setItem('rolsesion',datosRetornados["data"]["usuario"]["idRol"]);
          localStorage.setItem('usulogeado',JSON.stringify(datosRetornados["data"]["usuario"]))
          localStorage.setItem('ofiselect',datosRetornados["data"]["usuario"]["idOficina"])
          await this.delay(300);
          console.log( localStorage.getItem('tokenUser'));
          
      estadoBtn = false;
      this.dataProcessService.addDataProcess('rut', datosInterface.rut);
      localStorage.setItem('rut',datosInterface.rut);
      
      this.SweetAlertService.swalSuccess("Éxito", "Bienvenido Nuevamente");
      this.volver();
    }
    else{
      await this.SweetAlertService.swalError("¡Error, Por favor intente nuevamente!");
      window.location.reload()
    }
  }

    delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  validaRut(): void {
    this.rutValid = this.RutService.validarRut(this.myGroup.value.usuarioRut.replace(/\./g, '').replace('-', ''));
    this.myGroup.patchValue({ usuarioRut: this.RutService.formateaRut(this.myGroup.value.usuarioRut) });
  }

  TablaUser(): void {
    //e.preventDefault();
    this.router.navigate(['MenuOpciones']);
  }
  EsSubmit(){
    this.submited && !this.rutValid
  }
  groupSubmit(dato:any){
    this.submited && this.myGroup.get(dato).errors
  }
}
